import React, { Component } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './story.scss';
import play from '../../../assets/imgs/icons/play.svg';
import { Link } from "react-router-dom";
import crossArrow from '../../../assets/imgs/icons/cross.svg';
import Fade from 'react-reveal/Fade';


class Story extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    render() {
        return (
            <section className="comnSec globe-story section-padding d-md-flex align-items-center">
                {/* for fobile device start */}
                <Container className="d-md-none d-block">
                    <Fade bottom>
                        <h2 className="display-1 mb-4 title">{this.props.data.title}</h2>
                    </Fade>
                </Container>
                {/* for fobile device end */}

                <Fade>
                    <img alt="" src={this.props.data.media.images[0]} className="cover-img" alt="" />
                </Fade>
                <Fade bottom>
                    <div className="video-holder">
                        {

                            this.props.data.media.vidoes[0] !== '' ? (
                                <Link to="/home" onClick={() => this.setModalShow(this.props.data.media.vidoes[0] !== '' ? true : false)} className="d-block position-relative">
                                    <span className="play">
                                        <ReactSVG src={play} style={{ width: 18 + 'px' }} />
                                    </span>
                                    <img src={this.props.data.media.images[1]} />
                                </Link>
                            ) :
                                (
                                    <div className="d-block position-relative">
                                        <img src={this.props.data.media.images[1]} />
                                    </div>
                                )
                        }

                    </div>
                </Fade>

                {/* for mobile device start */}
                <Fade bottom cascade>
                    <Container className="d-block d-md-none overflow-hidden pt-4">
                        <p className="mb-4">{this.props.data.description}</p>
                        <Link to={'/' + this.props.data.links[0].link} className="h6">{this.props.data.links[0].label}</Link>
                    </Container>
                </Fade>
                {/* for mobile device end */}

                <Container>
                    <Row>
                        <Col md={{ span: 4, offset: 8 }}>
                            <Fade bottom cascade>
                                <figure className="pb-0 d-none d-md-block">
                                    <h2 className="display-1 mb-5">{this.props.data.title}</h2>
                                    <p>{this.props.data.description}</p>
                                    <Link to={'/' + this.props.data.links[0].link} className="h4">{this.props.data.links[0].label}</Link>
                                </figure>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                {/* video modal */}
                <VideoModal
                    videolink={this.props.data.media.vidoes[0]}
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                />
            </section>
        )
    }
}

function VideoModal(props) {
    return (
        <Modal {...props} size="lg" centered>
            <Button onClick={props.onHide} className="modal-close">
                <ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
            </Button>
            <Modal.Body>
                <iframe id="ytplayer" type="text/html" width="100%" height="405"
                    src={props.videolink + '?autoplay=1&disablekb=1'}
                    frameBorder="0" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
    );
}


export default Story;