import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './create.scss';
import Fade from 'react-reveal/Fade';


class Create extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="comnSec create">
                <Container className="section-padding">
                    <Row>
                        <Col lg={5} md={6}>
                            <Fade bottom>
                                <h3 className="display-4 mb-md-3 mb-4">{this.props.data.title}</h3>
                            </Fade>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }} md={6}>
                            <Fade bottom>
                                <p>{this.props.data.description}</p>
                            </Fade>
                        </Col>
                        <Col md={9} className="pt-4 pt-md-5">
                            <Fade bottom>
                                <h2 className="display-3 mb-0" dangerouslySetInnerHTML={this.createMarkup(this.props.data.sub_title)}></h2>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Create;