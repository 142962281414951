import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import downArrow from '../../../assets/imgs/icons/down-arrow.svg';
import './banner.scss';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

let _mode = true;

class Banner extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    componentDidMount() {
        const _target = document.getElementsByTagName('body')[0];
        const _header = document.getElementsByClassName('headerWrap')[0];
        // _header.style.width = _target.offsetWidth+'px';

    }

    componentWillUnmount() {
        const _header = document.getElementsByClassName('headerWrap')[0];
        // _header.style.width = 'auto';
    }

    scrollDown = () => {
        const _targetSection = document.getElementsByClassName('an-echo')[0];
        const _targetOffsetTop = _targetSection.offsetTop;
        const _headerHeight = document.getElementsByClassName('main-header')[0].clientHeight;
        window.scroll({
            top: (_targetOffsetTop - _headerHeight),
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <section className="about-banner text-center">
                <Fade>
                    <img src={this.props.data.image} className="object-cover banner-img"></img>
                </Fade>
                <Link to="/about" onClick={this.scrollDown} className="d-md-inline-block d-none next-link"><ReactSVG className="pulse-arrow m-auto" src={downArrow} style={{ width: 16 + 'px' }} /></Link>

            </section>

        )
    }
}




export default Banner;