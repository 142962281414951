import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './explore.scss';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

class Explore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            curentPage: ''
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    componentDidMount() {
        this.setState({ curentPage: this.props.match.params.page });
    }

    render() {
        const sectionMarginClass = this.state.curentPage === 'development' ? 'section-margin-b' : '';
        const alignItemClass = this.state.curentPage === 'innovation' ? 'align-items-start' : 'align-items-end'

        return (
            <section className={"cmnPmH explore-section " + sectionMarginClass}>
                <div className={'h-m-100-vh d-flex flex-column justify-content-end pt-0 pt-md-5 position-relative ' + alignItemClass}>
                    <img src={this.props.data.image} alt="" className="h-100 w-100 object-cover position-absolute top left content-img" />
                    <Fade bottom>
                        <div className="bg-white p-0 px-lg-5 pt-lg-5 content-box">
                            <div className="half-container p-0">
                                <div className="px-4 pt-4 px-md-5 pt-md-5">
                                    <span className="d-block mb-3 text-primary">{this.props.data.sub_title}</span>
                                    <span className="d-block h1 font-family-body mb-4">{this.props.data.title}</span>
                                    <span className="d-block sw-75 ">{this.props.data.description}</span>
                                    {this.props.data.hasOwnProperty('buttons') ? (
                                        <span className='d-block mt-4 link-group is-empty'>
                                            {
                                                this.props.data.buttons.map((item, index) => {
                                                    return item.button_label !== '' ? (
                                                        <Link key={'explore-button-' + index} className="h6 text-uppercase d-inline-block" to={item.button_link === 'contact' ? '/contact' : '/solutions/' + this.props.match.params.page + '/' + item.button_link}>{item.button_label}</Link>
                                                    ) : ''
                                                })
                                            }
                                        </span>
                                    ) : ''}

                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className={"container section-padding pb-0 section-margin-t section-border section-border-before " + (this.state.curentPage == 'development' || this.state.curentPage == 'logistics' ? 'd-none' : '')}></div>
            </section>
        )
    }
}

export default withRouter(Explore);