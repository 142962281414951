import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './growth.scss';
import Fade from 'react-reveal/Fade';

class Growth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    render() {
        return (
            <section className="feedback d-flex align-items-center position-relative">
                <Fade>
                    <img src={this.props.data.image} width="45%" height="100%" className="object-cover position-absolute left top"></img>
                </Fade>
                <Container>
                    <Row>
                        <Col md={{ span: 6, offset: 6 }}>
                            <Fade bottom cascade>
                                <figure className="mb-0">
                                    <sub className="react-reveal d-block display-3">"</sub>
                                    <h2 className="display-1 mb-4 mb-md-5 w-sm-75">{this.props.data.quote}</h2>
                                    <div className="w-sm-50">
                                        <span className="d-block text-primary">{this.props.data.name}</span>
                                        <span className="d-block">{this.props.data.designation}</span>
                                    </div>
                                </figure>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Growth;