import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './hero-detail.scss';
import Fade from 'react-reveal/Fade';



class HeroDetail extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="bg-primary text-white section-padding">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Fade bottom>
                                <span className="display-1 text-white mb-lg-5 mb-4 d-block">{this.props.data.title}</span>
                            </Fade>
                        </Col>
                        <Col md={6} className="offset-md-6 pr-md-5">
                            <Fade bottom>
                                <span className="d-block h3 font-family-body text-white">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

            </section>
        )
    }
}

export default HeroDetail;