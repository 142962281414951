import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './story.scss';
import TitleComponent from '../../../components/titles/title';

class GlobeStory extends Component {

	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.state = {
			pageData: null
		};
		
	} 

	next() {
		this.slider.slickNext();
	}
	
	componentDidMount() {
		axios.post('/stories', { section: 'ALL' }).then(response => {
			this.setState({ pageData: response.data.stories });
		})
	}

	render() {
		const settings = {
			customPaging: function (i) {
				return (
					<Link to="/story"><span className="progress-value"></span></Link>
				);
			},
			dots: true,
			arrows: false,
			dotsClass: "slick-dots slick-progress",
			speed: 800,
			fade: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			pauseOnHover: false,
			focusOnSelect: true
		};

		const mainSection = this.state.pageData !== null ? (
			<section className="custom-arrow-cursor">
				<Slider {...settings} className="stroy-carousel" ref={c => (this.slider = c)}>
					{this.state.pageData.map((item, index) => {
						return (<article className="item d-flex" key={'stroy-slider-' + index} onClick={this.next}>
							<Container className="position-relative z-index-1 flex-1 d-flex align-items-end align-items-md-center mb-4 mb-md-0">
								<Row>
									<Col xl={10} className="mb-lg-5 mb-3">
										<Fade bottom>
											<h2 className="display-1 mb-0 text-white">{item.title}</h2>
										</Fade>
									</Col>
									<Col md={8}>
										<Fade bottom>
											<h3 className="font-family-body text-white">{item.description}</h3>
										</Fade>
									</Col>
								</Row>
							</Container>
							<Container fluid="true" className="position-relative z-index-1 flex-none px-0">
								<Row noGutters="false">
									<Col className="d-flex align-items-center title-wrap">
										<Fade bottom>
											<h4 className="text-white title text-uppercase mb-0">{item.sl_no}. {item.name}</h4>
										</Fade>
									</Col>
									<Col>
										<Fade>
											<Link to="/solutions" className="discover h4 mb-0">DISCOVER</Link>
										</Fade>
									</Col>
								</Row>
							</Container>
							<Fade>
								<img src={item.image} className="cover-img" />
							</Fade>
						</article>)
					})}
				</Slider>
			</section>
		) : '';

		return (
			<React.Fragment>
				<TitleComponent title="" description="" />
				{mainSection}
			</React.Fragment>
		)
	}
}

export default GlobeStory;