import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './timing.scss';
import Fade from 'react-reveal/Fade';

class Timing extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="position-relative innovation-timing bg-primary text-white">
                <Fade>
                    <img src={this.props.data.media.image} className="cover-img mb-4 mb-md-0" />
                </Fade>
                <Container>
                    <Row>
                        <Col lg={5} md={6}>
                            <Fade bottom>
                                <article className="py-md-4">
                                    <div className=" text-white mb-lg-5 mb-4 d-block font-family-body process-header" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></div>
                                    <div className="d-block" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description)}></div>
                                </article>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Timing;