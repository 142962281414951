import React from 'react';
import Helmet from 'react-helmet';

const TitleComponent = ({ title, description }) => {
    var defaultTitle = process.env.REACT_APP_WEBSITE_TITLE;
    var defaultDescriptions = process.env.REACT_APP_WEBSITE_DESCRIPTIONS;
    return (
        <Helmet>
            <title>{title ? title : defaultTitle}</title>
            <meta property="og:title" content={title ? title : defaultTitle} />
            <meta property="twitter:title" content={title ? title : defaultTitle}></meta>
            <meta name="twitter:title" content={title ? title : defaultTitle}></meta>

            <meta name="description" content={description ? description : defaultDescriptions} />
            <meta property="og:description" content={description ? description : defaultDescriptions} />
            <meta property="twitter:description" content={description ? description : defaultDescriptions} />
            <meta name="twitter:description" content={description ? description : defaultDescriptions} />

            <meta property="og:image" content={process.env.REACT_APP_SITE_BASE_URL + '/site.jpg'} />
            <meta property="twitter:image" content={process.env.REACT_APP_SITE_BASE_URL + '/site.jpg'} />
            <meta name="twitter:image" content={process.env.REACT_APP_SITE_BASE_URL + '/site.jpg'} />
        </Helmet>
    );
};

export default TitleComponent;