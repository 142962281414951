import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './contact.scss';
import MapContainer from '../../../components/google-map/google-map';
import crossArrow from '../../../assets/imgs/icons/cross.svg';
import ReactSVG from 'react-svg';
import ContactForm from '../../../components/contact/contact-form';
import MainFooter from '../../../components/footer/footer';
import Map from '../../../assets/imgs/map.jpg';
import { Link } from "react-router-dom";
import TitleComponent from '../../../components/titles/title';

let _targetElement = '';

class GlobeContact extends Component {

	constructor(props) {
		super(props);
		this.state = {
			modalShow: false,
			modalMessage: '',
			pageData: null

		};
	}

	createMarkup(data) {
		return { __html: data };
	}

	setModalShow = (param) => {
		this.setState({ modalShow: param });
	}

	componentDidMount() {
		window.scroll(0, 0);
		_targetElement = document.getElementsByClassName('header-wrapper')[0];
		_targetElement.classList.add('bluNav');

		axios.post('/contact', { section: 'ALL' }).then(response => {
			this.setState({ pageData: response.data.page });
		})
	}

	componentWillUnmount() {
		_targetElement.classList.remove('bluNav');
	}

	onMessageSent(message) {
		this.setState({ modalMessage: message });
		this.setModalShow(true)
	}

	parseOfficeTime(date) {
		const newDate = new Date(date);
		return newDate.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':' + newDate.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2 });
	}

	render() {

		const section = this.state.pageData !== null ? (
			<div className="contact-page">
				<div className="contact-content">
					{/* <span className="line wwc-line"></span> */}
					<section className="cmnPmH position-relative section-padding">
						<Container className="pt-3 pt-sm-4 pt-md-5">
							<Row>
								<Col md={6} className="d-flex align-items-end">
									<Fade bottom>
										<h2 className="d-block display-1 text-primary mb-0">{this.state.pageData.section_1.title}</h2>
									</Fade>
								</Col>
								<Col md={6} className="d-flex align-items-end">
									<Fade bottom cascade>
										<span className="d-block mt-3 mt-md-5" dangerouslySetInnerHTML={this.createMarkup(this.state.pageData.section_1.description)}></span>
									</Fade>
								</Col>
							</Row>
						</Container>
					</section>

					<section className="bg-primary cmnPmH position-relative text-white section-padding">
						{/* <span className="line wwc2-line"></span> */}
						<Container>
							<Row>
								<Col sm={4} className="mb-4 mb-sm-0">
									<Fade bottom>
										<div className="mb-2">{this.state.pageData.section_2.office_time_label}</div>
										<span className="d-block display-1 mb-2 mb-sm-4 text-white">{this.state.pageData.section_4.offices[0].get_date}</span>
										{/* <span className="text-white d-block mb-2 mb-sm-3">{this.state.pageData.section_4.offices[0].contact}</span> */}
										<a className="text-white d-block" href={"mailto:" + this.state.pageData.section_4.offices[0].email_address}>{this.state.pageData.section_4.offices[0].email_address}</a>
									</Fade>
								</Col>
								<Col sm={8}>
									<Fade bottom>
										<div className="mb-2">{this.state.pageData.section_2.office_open_label}</div>
										<span className="d-block display-1 mb-2 mb-sm-4 text-white">{this.state.pageData.section_4.offices[0].place_name}{this.state.pageData.section_4.offices[0].is_hq ? ' HQ' : ''}</span>
										<div className="mb-2 mb-sm-3">{this.state.pageData.section_4.offices[0].address_line_1}</div>
										<div className="text-white">{this.state.pageData.section_4.offices[0].address_line_2}</div>
									</Fade>
								</Col>
							</Row>
						</Container>
					</section>

					{/* start map section */}
					<section className="cmnPmH position-relative mapContainer z-index-1">
						<a href="https://goo.gl/maps/1GJvdkDmvXPQfiQr5" target="_blank">
							<img src={Map} className="object-cover overflow-hidden" width="100%" height="100%" />
						</a>
						{/* <MapContainer lat={this.state.pageData.section_4.offices[0].latitude} long={this.state.pageData.section_4.offices[0].longitude} ></MapContainer> */}
					</section>
					{/* end map section */}

					<section className="cmnPmH position-relative section-padding">
						<Container>
							<Row>
								<Col md={6}>
									<Fade bottom>
										<h2 className="d-block display-3 text-primary mb-3 mb-md-0">{this.state.pageData.section_3.title}</h2>
									</Fade>
								</Col>
								<Col md={6}>
									<Fade bottom>
										<ContactForm data={this.state.pageData.section_3} onSuccess={this.onMessageSent.bind(this)}></ContactForm>
									</Fade>
								</Col>
							</Row>
						</Container>
					</section>

					<section className="bg-primary cmnPmH position-relative text-white section-padding office-list">
						<Container>
							<Fade bottom>
								<div className="mb-2">
									<Row>
										<Col xs={5} sm={3}>
											<div>{this.state.pageData.section_4.office_time_label}</div>
										</Col>
										<Col xs={7} sm={6}>
											<div>{this.state.pageData.section_4.office_open_label}</div>
										</Col>
									</Row>
								</div>
								{this.state.pageData.section_4.offices.map((office, index) => {
									const isOpenClass = office.is_open ? 'text-white' : 'text-secondary';
									return (
										<Row key={'office-list-' + index}>
											<Col xs={5} sm={3}>
												<span className={'d-block display-1 m-0 ' + isOpenClass}>{office.get_date}</span>
											</Col>
											<Col xs={7} sm={6}>
												<span className={'d-block display-1 m-0 ' + isOpenClass}>{office.place_name}{office.is_hq ? ' HQ' : ''}</span>
											</Col>
										</Row>
									);
								})}
							</Fade>
						</Container>
					</section>
					<StatusModal show={this.state.modalShow} message={this.state.modalMessage} onHide={() => this.setModalShow(false)} animation={true} />
				</div>
				<MainFooter></MainFooter>
			</div>
		) : null;

		return (
			<React.Fragment>
				<TitleComponent title="" description="" />
				{section}
			</React.Fragment>
		);
	}
}


export default GlobeContact;

function StatusModal(props) {
	return (
		<Modal {...props} size="xs" centered>
			<Button onClick={props.onHide} className="modal-close">
				<ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
			</Button>
			<Modal.Body>{props.message}</Modal.Body>
			<Modal.Footer className="py-2">
				<Button variant="primary" onClick={props.onHide}>OK</Button>
			</Modal.Footer>
		</Modal>
	);
}
