import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import SolutionLanding from "../../../components/solution-detail/solution-landing/solution-landing";
import ShortDetail from "../../../components/solution-detail/short-detail/short-detail";
import SolutionCarousel from "../../../components/solution-detail/solution-carousel/solution-carousel";
import Explore from "../../../components/solution-detail/explore/explore";
import Solutions from "../../../components/solution-detail/solutions/solutions";
import Growth from "../../../components/solution-detail/growth/growth";
import Fabrics from "../../../components/solution-detail/fabrics/fabrics";
import Services from "../../../components/solution-detail/services/services";
import Fashion from "../../../components/solution-detail/fashion/fashion";
import Ready from "../../../components/solution-detail/ready/ready";
import SolutionAccordion from "../../../components/solution-detail/solution-accordion/solution-accordion";
import ShortDescription from "../../../components/solution-detail/short-description/short-description";
import SolutionDetails from "../../../components/solution-detail/solution-details/solution-details";

import MainFooter from "../../../components/footer/footer";
import TitleComponent from '../../../components/titles/title';

class GlobeSolutionDetail extends Component {

	_targetElement = '';

	constructor(props) {
		super(props);
		this.state = {
			pageData: null,
			menus: null,
			reletedSolutions: null
		};
	}

	componentDidMount() {
		window.scroll(0, 0);
		this._targetElement = document.getElementsByClassName('header-wrapper')[0];
		this._targetElement.classList.add('bluNav');

		axios.post('/solution_menus', { section: 'ALL'}).then(response => {
			this.setState({ menus: response.data.page.menu });
		})
		axios.post('/solution_type', { section: 'ALL', page: this.props.match.params.page }).then(response => {
			this.setState({ pageData: response.data.page });
		})
	}
	componentWillUnmount() {
		this._targetElement.classList.remove('bluNav');
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.page !== prevProps.match.params.page) {
			this.setState({ pageData: null });
			axios.post('/solution_type', { section: 'ALL', page: this.props.match.params.page }).then(response => {
				this.setState({ pageData: response.data.page });
			})
		}
	}

	render() {

		let commonSection = null;
		let mainSection = null;

		if (this.state.pageData !== null && this.state.menus !== null) {
			const details = this.state.pageData.hasOwnProperty('details') ? (<ShortDetail key="solution-details" data={this.state.pageData.details}></ShortDetail>) : '';
			const explore = this.state.pageData.hasOwnProperty('explore') ? <Explore curentPage={this.props.match.params.page} key="solution-explore" data={this.state.pageData.explore}></Explore> : '';
			const solutions = this.state.pageData.hasOwnProperty('solutions') ? <Solutions key="solution-solutions" data={this.state.pageData.solutions} menu={this.state.menus.items}></Solutions> : '';
			const growth = this.state.pageData.hasOwnProperty('growth') ? <Growth key="solution-growth" data={this.state.pageData.growth}></Growth> : '';
			const fabrics = this.state.pageData.hasOwnProperty('fabrics') ? <Fabrics key="solution-fabrics" data={this.state.pageData.fabrics}></Fabrics> : '';
			const services = this.state.pageData.hasOwnProperty('services') ? <Services key="solution-services" data={this.state.pageData.services}></Services> : '';
			const fashion = this.state.pageData.hasOwnProperty('fashion') ? <Fashion key="solution-fashion" data={this.state.pageData.fashion}></Fashion> : '';
			const ready = this.state.pageData.hasOwnProperty('ready') ? <Ready key="solution-ready" data={this.state.pageData.ready}></Ready> : '';
			const faq = this.state.pageData.hasOwnProperty('faq') ? <SolutionAccordion key="solution-faq" data={this.state.pageData.faq}></SolutionAccordion> : '';
			// finance spacial component
			const details_1 = this.state.pageData.hasOwnProperty('details_1') ? <ShortDescription key="solution-details_1" data={this.state.pageData.details_1}></ShortDescription> : '';
			const details_2 = this.state.pageData.hasOwnProperty('details_2') ? <SolutionDetails key="solution-details_2" data={this.state.pageData.details_2}></SolutionDetails> : '';


			switch (this.props.match.params.page) {
				case 'advice':
					mainSection = [details, explore, solutions, growth]
					break;
				case 'innovation':
					mainSection = [details, fabrics, explore, growth]
					break;
				case 'marketing':
					mainSection = [details, services, explore, growth]
					break;
				case 'design':
					mainSection = [details, fabrics, ready]
					break;
				case 'development':
					mainSection = [details, explore, fabrics]
					break;
				case 'logistics':
					mainSection = [details, explore]
					break;
				case 'finance':
					mainSection = [details, details_1, details_2, faq]
					break;
				default:
					break;
			}

			commonSection = (
				<React.Fragment>
					{/* <span className="line line-wws-1"></span>
					<span className="line line-wws-2"></span> */}
					<SolutionLanding menu={this.state.menus.items}></SolutionLanding>
					{mainSection}
					<SolutionCarousel data={this.state.pageData.slider}></SolutionCarousel>
					<MainFooter></MainFooter>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<TitleComponent title="" description="" />
				{commonSection}
			</React.Fragment>
		)
	}
}

export default withRouter(GlobeSolutionDetail);