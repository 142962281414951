import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './ready.scss';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

class Ready extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    render() {
        return (
            <div className="h-vh position-relative mh-50vh d-flex flex-column align-items-center ready">
                <img src={this.props.data.image} className="w-50 h-100 position-absolute top left ready-img object-contain" />
                <div className="custom-heading w-50 h-100">
                    <h2 className="display-1 text-white mb-0">{this.props.data.sec4_imagealt}</h2>
                </div>
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} sm={{ span: 6, offset: 6 }} className="d-flex align-items-center position-relative z-index-1">
                            <div className="px-0 pt-4 pb-sm-4 p-md-5">
                                <Fade bottom>
                                    <h2 className="display-4 mb-3 mb-sm-5 text-primary">{this.props.data.title}</h2>
                                    <span className="mb-3 mb-md-5 d-block">{this.props.data.description}</span>
                                    <Link className="btn btn-primary btn-xl text-uppercase" to={this.props.data.button_link === 'contact' ? '/contact' : '/' + this.props.data.button_link}>{this.props.data.button_label}</Link>
                                </Fade>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}


export default Ready;