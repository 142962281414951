import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './short-detail.scss';
import { Link } from "react-router-dom";
import play from '../../../assets/imgs/icons/play.svg';
import crossArrow from '../../../assets/imgs/icons/cross.svg';
import Fade from 'react-reveal/Fade';


class ShortDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    render() {
        return (
            <section>
                <Container className="section-padding">
                    <Row>
                        <Col xs={12} className="">
                            <Fade bottom>
                                <h2 className="display-1 mb-0">
                                    {this.props.data.title}
                                </h2>
                            </Fade>
                        </Col>
                        <Col md={4} className="section-padding pb-0 text-md-right offset-md-2">
                            <Fade bottom>
                                <div className="video-holder w-100 d-inline-block pr-md-5 mr-md-5">

                                    {
                                        this.props.data.video_link !== '' ? (
                                            <Link to="#" onClick={() => this.setModalShow(true)} className="d-block position-relative">
                                                <span className="play">
                                                    <ReactSVG src={play} style={{ width: 18 + 'px' }} />
                                                </span>
                                                <img className="w-100" alt="" src={this.props.data.image} />
                                            </Link>
                                        ) : (
                                                <div className="d-block position-relative">
                                                    <img className="w-100" alt="" src={this.props.data.image} />
                                                </div>
                                            )
                                    }

                                </div>
                            </Fade>
                        </Col>
                        <Col md={6} className="section-padding pb-0 short-detail-content">
                            <Fade bottom>
                                <span className="d-block h2 font-family-body mb-0 cont">{this.props.data.description}</span>
                                <Link to={this.props.data.button_link === 'contact' ? '/contact' : '/solutions/' + this.props.match.params.page + '/' + this.props.data.button_link} className="h4 text-uppercase mt-4 d-inline-block mb-0">{this.props.data.button_label}</Link>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                {/* video modal */}
                <VideoModal
                    videolink={this.props.data.video_link}
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                />
            </section>
        )
    }
}

function VideoModal(props) {
    return (
        <Modal {...props} size="lg" centered>
            <Button onClick={props.onHide} className="modal-close">
                <ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
            </Button>
            <Modal.Body>
                <iframe id="ytplayer" type="text/html" width="100%" height="405"
                    src={props.videolink + '?autoplay=1&disablekb=1'}
                    frameBorder="0" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
    );
}

export default withRouter(ShortDetail);