import React, { Component } from 'react';
import axios from 'axios';
import Banner from '../../../components/about/banner/banner';
import Info from '../../../components/about/info/info';
import EcoSystem from '../../../components/about/eco-system/eco-system';
import Fashion from '../../../components/about/fashion/fashion';
import Togather from '../../../components/about/togather/togather';
import Footer from '../../../components/footer/footer';
import Industry from '../../../components/about/industry/industry';
import TitleComponent from '../../../components/titles/title';

class GlobeAbout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageData: null
		};
	}

	componentDidMount() {
		this.mainContentAreaEl = document.getElementsByClassName('mainContentArea')[0];
		this.mainContentAreaEl.classList.add('about-container');

		axios.post('/wiser_about', { section: 'ALL' }).then(response => {
			this.setState({ pageData: response.data.page });
		})
	}

	componentWillUnmount() {
		this.mainContentAreaEl.classList.remove('kkkkk')
	}

	render() {
		let aboutUsSection = null;
		if (this.state.pageData !== null) {
			aboutUsSection = (
				<div className="pmAboutSec">
					<div className="position-relative d-flex flex-column">
						<Banner data={this.state.pageData.banner_section} info={this.state.pageData.section_1}></Banner>
						<Info data={this.state.pageData.section_1}></Info>
					</div>
					<EcoSystem data={this.state.pageData.section_2}></EcoSystem>
					<Fashion data={this.state.pageData.section_3}></Fashion>
					<Industry data={this.state.pageData.section_4}></Industry>
					<Togather data={this.state.pageData.section_5}></Togather>
					<Footer></Footer>
				</div>
			)
		}
		return (
			<React.Fragment>
				<TitleComponent title="" description="" />
				{aboutUsSection}
			</React.Fragment>
		)
	}
}

export default GlobeAbout;