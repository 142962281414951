import React, { Component } from 'react';
import axios from 'axios';
import './home.scss';
import HomeLanding from '../../../components/home/landing/landing';
import Story from '../../../components/home/story/story';
import Innovation from '../../../components/home/innovation/innovation';
import Academy from '../../../components/home/academy/academy';
import Challenge from '../../../components/home/challenge/challenge';
import Create from '../../../components/home/create/create';
import EventCarousel from '../../../components/home/event/event';
import Feedback from '../../../components/home/feedback/feedback';
import FAQ from '../../../components/home/faq/faq';
import Footer from '../../../components/footer/footer';
import TitleComponent from '../../../components/titles/title';

class GlobeHome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageData: null
		};
	}

	componentDidMount() {
		window.scroll(0, 0);
		axios.post('/wiser_globe', { section: 'ALL' }).then(response => {
			this.setState({ pageData: response.data.page });
		})
	}

	render() {
		const homeSection = this.state.pageData ? (
			<div className="home-wrap">
				<div className="lineWrap">
					{/* <span className="line line-wwh-1"></span>
					<span className="line line-wwh-2"></span>
					<span className="line line-wwh-3"></span> */}
				</div>
				<HomeLanding data={this.state.pageData.banner_section}></HomeLanding>
				<Story data={this.state.pageData.section_1}></Story>
				<Innovation data={this.state.pageData.section_2}></Innovation>
				<Challenge data={this.state.pageData.section_3}></Challenge>
				<Create data={this.state.pageData.section_4}></Create>
				<Academy data={this.state.pageData.section_5}></Academy>
				<EventCarousel data={this.state.pageData.section_6}></EventCarousel>
				<Feedback data={this.state.pageData.section_7}></Feedback>
				<FAQ data={this.state.pageData.section_8}></FAQ>
				<Footer></Footer>
			</div>
		) : null;

		return (
			<React.Fragment>
				<TitleComponent title="" description="" />
				{homeSection}
			</React.Fragment>
		)
	}
}

export default GlobeHome;