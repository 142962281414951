import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './feedback.scss';
import Fade from 'react-reveal/Fade';


class Feedback extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="comnSec feedback d-flex align-items-center position-relative">
                <Fade>
                    <img src={this.props.data.image} width="45%" height="100%" className="object-cover position-absolute left top"></img>
                </Fade>
                <Container>
                    <Row>
                        <Col xs={7} md={{ span: 6, offset: 6 }}>
                            <Fade bottom cascade>
                                <figure className="mb-0">
                                    <sub className="react-reveal d-block display-3">"</sub>
                                    <h2 className="display-1 mb-lg-5 mb-3">{this.props.data.title}</h2>
                                    <span className="d-block text-primary w-75">{this.props.data.name}</span>
                                    <span className="d-block w-75" dangerouslySetInnerHTML={this.createMarkup(this.props.data.designation)}></span>
                                </figure>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>

        )
    }
}




export default Feedback;