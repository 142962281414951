import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './solution-carousel.scss';
import { Link } from "react-router-dom";
import nextArrow from '../../../assets/imgs/icons/next-arrow.svg';
import prevArrow from '../../../assets/imgs/icons/prev-arrow.svg';
import Slider from "react-slick";
import Equalizer from 'react-equalizer';
import Fade from 'react-reveal/Fade';
import axios from 'axios';

// SamplePrevArrow
function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div className="prev-container">
            <Container className="position-relative">
                <div className={className} onClick={onClick}>
                    <ReactSVG src={prevArrow} style={{ width: 32 + 'px' }} />
                </div>
            </Container>
        </div>
    );
}

// SampleNextArrow
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Container className="position-relative">
            <div className={className} onClick={onClick}>
                <ReactSVG src={nextArrow} style={{ width: 32 + 'px' }} />
            </div>
        </Container>
    );
}


class SolutionCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            reletedSolutions: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
        axios.post('/related_solutions', { section: 'ALL', page: this.props.match.params.page }).then(response => {
            this.setState({ reletedSolutions: response.data });
        })
    }

    render() {
        const navMenu = this.state.reletedSolutions;
        const carouselSettings = {
            dots: false,
            infinite: false,
            className: "center",
            centerMode: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        variableWidth: false,
                    }
                }
            ]
        }

        const slideContent = {
            slidesToShow: 3,
            swipeToSlide: true,
            focusOnSelect: true,
            centerMode: true,
            infinite: false,
            arrows: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        arrows: true,
                        centerMode: false,
                        centerPadding: 0
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        arrows: true,
                        centerMode: false,
                        centerPadding: 0
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        arrows: true,
                        centerMode: true,
                        centerPadding: 32
                    }
                }
            ]
        }

        return (

            <section className="position-relative d-flex justify-content-center flex-column w-100 carousel-wrap section-padding pb-0">
                <Container className="section-padding pb-0 section-border section-border-before">
                    <Row className="section-margin-b">
                        <Col md={5}>
                            <Fade bottom>
                                <h3 className="display-4 mb-3 mb-md-0">{this.props.data.title}</h3>
                            </Fade>
                        </Col>
                        <Col md={{ span: 6, offset: 1 }}>
                            <Fade bottom>
                                <p className="mb-0">{this.props.data.description}</p>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                {/* slider for desktop version start */}
                {this.state.reletedSolutions !== null ? (
                    <>
                        <Slider {...carouselSettings} className="carousel-primary d-xl-block d-none section-margin-b z-index-1 position-relative">
                            {
                                navMenu.map((item, index) => {
                                    return (
                                        <Container className="item position-relative" key={'solution-ca-des-' + index}>
                                            {item.details.image ? (
                                                <Fade fade>
                                                    <img src={item.details.image} alt="" className="right bottom z-index-1 position-absolute object-cover" width="50%" height="430" />
                                                </Fade>
                                            ) : ''}
                                            <Row noGutters="true">
                                                <Col md={12} className="bg-primary text-white text-left custom-cursor">
                                                    <Fade bottom cascade>
                                                        <Link to={item.link === 'contact' ? '/contact' : '/' + item.link} className="content custom-cursor animation-flicker-stop">
                                                            <span className="d-block display-1 text-white mb-4">{item.details.sub_title} <br />{item.details.title}</span>
                                                            <span className="d-block text-white">{item.details.description}</span>
                                                        </Link>
                                                    </Fade>
                                                </Col>

                                                <Col md={12} className="text-left discover d-flex align-items-center">
                                                    <Fade bottom>
                                                        <Link to={item.link === 'contact' ? '/contact' : '/' + item.link} className="d-block h6 text-primary mb-0 text-uppercase">DISCOVER OUR {item.sub_title} SOLUTIONS</Link>
                                                    </Fade>
                                                </Col>
                                            </Row>
                                        </Container>
                                    )
                                })
                            }
                        </Slider>

                        {/* slider for desktop version end */ }

                        <Container fluid="true" className="d-block d-xl-none px-4 m-0 position-relative mob-carousel">
                            <div className="slideContent">
                                <Slider asNavFor={this.state.nav1} ref={slider => (this.slider2 = slider)} {...slideContent}>
                                    {
                                        navMenu.map((item, index) => {
                                            return (
                                                <Link to={item.link === 'contact' ? '/contact' : '/' + item.link} className="content bg-primary text-white p-4" key={'solution-ca-mob-' + index}>
                                                    <Equalizer>
                                                        <Fade>
                                                            <span className="d-block display-1 text-ellipsis mb-4 text-white">{item.details.sub_title} <br />{item.details.title}</span>
                                                            <span className="d-block">{item.details.description}</span>
                                                        </Fade>
                                                    </Equalizer>
                                                </Link>
                                            ) 
                                        })
                                    }
                                </Slider>
                            </div>
                        </Container>
                    </>
                ) : ''}

            </section>
        )
    }
}




export default withRouter(SolutionCarousel);