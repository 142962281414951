import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './innovation-menu.scss';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import prevArrow from '../../../assets/imgs/icons/cross.svg';


class InnovationMenu extends Component {

    createMarkup(data) {
        return { __html: data };
    }


    render() {
        let menu = null;
        if (this.props.menu !== null) {
            console.log(this.props.currentPage);
            menu = this.props.menu.map((item, index) => {
                return <li key={'innovation-details-buttom-nav-' + index} className="display-3"><Link className={item.link == this.props.currentPage ? 'opacity-5' : ''} to={'/innovations/' + item.link}>{item.title}</Link></li>
            })
        }

        return (
            <section className="bg-primary text-white section-padding innovation-menu-container">
                <Container className="section-padding pb-0 section-border section-border-before">

                    <Row>
                        <Col lg={5} md={6}>
                            <Fade bottom>
                                <h2 className="display-4 mb-4 mb-lg-5 text-white" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></h2>
                                <p className="" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description)}></p>
                            </Fade>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }} md={6}>
                            <Fade bottom cascade>
                                <ul className="list-mainmenu innovation-menu mb-0">
                                    {menu}
                                </ul>
                            </Fade>
                        </Col>
                    </Row>

                    <Fade bottom>
                        <div className="text-center section-padding pb-0">
                            <Link className="btn page-close btn-round inline-block rounded-circle" to="/">
                                <ReactSVG className="" style={{ width: 24 + "px", fill: "var(--primary)" }} src={prevArrow} />
                            </Link>
                        </div>
                    </Fade>
                </Container>

            </section>
        )
    }
}




export default InnovationMenu;