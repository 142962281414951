import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import './solution-landing.scss';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import nextArrow from '../../../assets/imgs/icons/next-arrow-white.svg';
import prevArrow from '../../../assets/imgs/icons/prev-arrow-white.svg';

class SolutionLanding extends Component {

    render() {
        const currentMenuIndex = this.props.menu.map(item => item.link).indexOf(this.props.match.params.page);
        const navMenu = [];
        if (currentMenuIndex === this.props.menu.length - 1) {
            navMenu.push(this.props.menu[currentMenuIndex - 1]);
            navMenu.push(this.props.menu[0]);
        } else if (currentMenuIndex === 0) {
            navMenu.push(this.props.menu[this.props.menu.length - 1]);
            navMenu.push(this.props.menu[currentMenuIndex + 1]);
        } else {
            navMenu.push(this.props.menu[currentMenuIndex - 1]);
            navMenu.push(this.props.menu[currentMenuIndex + 1]);
        }
        return (
            <section className="landing h-vh position-relative overflow-hidden">
                <div className="d-lg-block">
                    <div className="px-4 px-xl-5 landing-img">
                        <Fade>
                            <img src={this.props.menu[currentMenuIndex].banner_image} className="m-xl-auto object-cover h-vh" />
                        </Fade>
                    </div>
                </div>
                <div>
                    <Fade bottom>
                        <div className="position-absolute left bottom bg-primary text-white p-4 p-xl-5 w-50 w-md-75 w-xs-90 primary-box content-box">
                            <div className="p-xl-5">
                                <figure className="mb-0 text-white">
                                    <span className="d-block display-1 text-white">{this.props.menu[currentMenuIndex].title}</span>
                                    <span className="d-block display-1 mb-4 mb-lg-5 text-white">{this.props.menu[currentMenuIndex].sub_title}</span>
                                    <span className="lead w-75 w-xs-90 pr-0 pr-sm-5 des">{this.props.menu[currentMenuIndex].description}</span>
                                </figure>
                            </div>
                            <Link className="d-none d-md-flex h3 position-absolute bottom right py-3 px-5 mb-0 bg-white text-primary landing-button align-items-center justify-content-center" to="/solutions">DISCOVER</Link>
                        </div>
                    </Fade>
                </div>
                <div className="d-none d-lg-block">
                    <Fade bottom>
                        <div className="position-absolute right bottom d-flex align-items-end landing-nav">
                            {currentMenuIndex !== 0 && (
                                <Link className="prev" to={'/solutions/' + navMenu[0].link}>
                                    <ReactSVG className="" src={prevArrow} style={{ width: 32 + 'px' }} />
                                </Link>
                            )}

                            <Link className="next" to={'/solutions/' + navMenu[1].link}>
                                <figure className="mb-0">
                                    <span className="d-block h3 mb-0 text-white text-uppercase">{navMenu[1].title}</span>
                                    <span className="d-block h3 text-white text-uppercase">{navMenu[1].sub_title}</span>
                                </figure>
                                <span className="arCon">
                                    <ReactSVG className="" src={nextArrow} style={{ width: 32 + 'px' }} />
                                </span>
                            </Link>
                        </div>
                    </Fade>
                </div>
            </section>
        )
    }
}

export default withRouter(SolutionLanding);