import React, { Component } from 'react';
import { Container, Row, Col, Accordion, Button, Card } from 'react-bootstrap';
import './industry.scss';
import { Link, NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import Focus from '../../../assets/imgs/about/focus.jpg';
import Slider from "react-slick";

class Industry extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        const settings = {
            infinite: false,
            speed: 800,
            slidesToScroll: 1,
            slidesToShow: 1,
            arrows: false,
            variableWidth: true,
        };
        return (
            <section className=" about-industry">
                <section className="position-relative d-md-flex flex-wrap section-padding pt-0">
                    <Col md={6} className="px-0 d-md-block d-none">
                        <div className="half-container mr-0 ml-auto">
                            <Row>
                                <Col md={10}>
                                    {/* Academy start */}
                                    <Fade bottom cascade>
                                        <article className="mb-5">
                                            <h2 className="display-1 mb-3">{this.props.data.sub_title}</h2>
                                            <span className="d-block h2 font-family-body mb-4">{this.props.data.title}</span>
                                            <p className="mb-0" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description)}></p>
                                        </article>
                                    </Fade>
                                    {/* Academy end */}

                                    {/* B2C start */}
                                    <Fade bottom cascade>
                                        <article className="pt-0">
                                            <h2 className="display-1 mb-3">{this.props.data.sub_title2}</h2>
                                            <span className="d-block h2 font-family-body mb-4">{this.props.data.title2}</span>
                                            <p className="mb-0" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description2)}></p>
                                        </article>
                                    </Fade>
                                    {/* B2C end */}
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={6} className="p-0 position-relative z-index-1">
                        <div className="primary-box bg-primary d-flex flex-column justify-content-center align-items-center text-center text-white w-100 position-sticky top right" style={{ backgroundImage: `url(${this.props.data.image})` }}>
                            <Fade>
                                <figure className="z-index-1 stickyText mb-0">
                                    <h2 className="display-1 text-white mb-4 mb-lg-5" dangerouslySetInnerHTML={this.createMarkup(this.props.data.image_alt)}></h2>
                                </figure>
                            </Fade>
                        </div>
                    </Col>
                </section>

                {/* for mobile device start */}
                <Container className="d-block d-md-none pb-5 overflow-hidden">
                    <Slider {...settings} className="academy-carousel">
                        {/* B2B start */}
                        <article className="item bg-primary text-white p-4">
                            <Fade bottom>
                                <h2 className="display-1 mb-3 text-white">{this.props.data.sub_title}</h2>
                                <span className="d-block h2 mb-2 text-white">{this.props.data.title}</span>
                                <p className="mb-0" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description)}></p>
                            </Fade>
                        </article>
                        {/* B2B end */}
                        {/* B2C start */}
                        <article className="item bg-primary text-white p-4">
                            <Fade bottom>
                                <h2 className="display-1 mb-3 text-white">{this.props.data.sub_title2}</h2>
                                <span className="d-block h2 mb-2 text-white">{this.props.data.title2}</span>
                                <p className="mb-0" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description2)}></p>
                            </Fade>
                        </article>
                        {/* B2C end */}


                    </Slider>
                </Container>
                {/* for mobile device end */}
            </section>
        )
    }

}


export default Industry;