import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './info.scss';
import Fade from 'react-reveal/Fade';


class Info extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="about-info section-padding pt-0 pb-0">
                <div className="primary-r-box mr-0 w-50 ml-auto pmBlueBox">
                    <Fade bottom>
                        <h2 className="d-block display-1 text-white mb-0 bg-primary" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></h2>
                    </Fade>
                </div>
                {/* <Container> */}
                <Container fluid="tue" className="bg-white position-relative z-index-1 px-0">
                    <Container>
                        <Row className="position-relative z-index-1">

                            <Col className="bg-white" md={{ span: 6, offset: 6 }}>
                                <div className="half-container pt-4 pt-xl-5">
                                    <Fade bottom>
                                        <span className="d-block mb-4 mb-xl-5">{this.props.data.description}</span>
                                    </Fade>
                                </div>
                            </Col>
                            <Col lg={5} md={6} className="mb-0">
                                <Fade bottom>
                                    <span className="d-block h2 font-family-body text-primary mb-4 mb-xl-5" dangerouslySetInnerHTML={this.createMarkup(this.props.data.sub_title)}></span>
                                </Fade>
                            </Col>
                            <Col className="bg-white" md={6} lg={{ span: 6, offset: 1 }}>
                                <div className="half-container pt-0">
                                    <Fade bottom>
                                        <span className="d-block">{this.props.data.sub_description}</span>
                                    </Fade>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                {/* </Container> */}
                {/* <Container className="bg-white">
                    <Col className="fixedIndex">
                        <Fade>
                            <span className="d-block border-bottom section-padding pb-0"></span>
                        </Fade>
                    </Col>
                </Container> */}
            </section>
        )
    }
}




export default Info;