import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './hero-two-column.scss';
import Fade from 'react-reveal/Fade';
import ReactSVG from 'react-svg';
import { Link, withRouter } from "react-router-dom";
import prevArrow from '../../../assets/imgs/icons/cross.svg';

class HeroTwoColumn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            prevPath: '',
        };
    }

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="hero-two-column">
                <div className="position-relative bg-primary text-white">
                    <Fade>
                        <img src={this.props.data.image} className="cover-img mb-4 mb-md-0" />
                    </Fade>
                    <Container>
                        <Row>
                            <Col lg={5} md={6}>
                                <Fade bottom>
                                    <article className="py-md-5">
                                        <span className="d-block mb-4 text-white">{this.props.data.subtitle}</span>
                                        <span className=" text-white mb-4 d-block font-family-body process-header">{this.props.data.title}</span>
                                        <span className="d-block">{this.props.data.description}</span>
                                    </article>
                                </Fade>
                            </Col>
                        </Row>


                    </Container>
                </div>
                <Fade bottom>
                    <div className="text-center section-padding">
                        <button className="btn page-close btn-round inline-block rounded-circle" onClick={() => this.props.history.goBack()}>
                            <ReactSVG style={{ width: 24 + "px", fill: "var(--primary)" }} src={prevArrow} />
                        </button>
                    </div>
                </Fade>
            </section>
        )
    }
}


export default withRouter(HeroTwoColumn);