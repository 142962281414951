import * as actionTypes from '../actions/actionType';

function userDetails(state = {}, action) {
    switch (action.type) {
        case actionTypes.USER_DETAILS:
            return {
                ...state,
                'userDetails': action.details,//detail.data.data
                'isError': false,
                validate: true
            }
        default: return state
    }
}

export default userDetails;