import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import Fade from 'react-reveal/Fade';
import './solution.scss';
import nextArrow from '../../../assets/imgs/icons/next-arrow.svg';
import prevArrow from '../../../assets/imgs/icons/prev-arrow.svg';
import playBtn from '../../../assets/imgs/icons/play.svg';
import ReactSVG from 'react-svg';
import TitleComponent from '../../../components/titles/title';

let _target = '';

// SamplePrevArrow
function SamplePrevArrow(props) {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<ReactSVG src={prevArrow} style={{ width: 32 + 'px' }} />
		</div>
	);
}

// SampleNextArrow
function SampleNextArrow(props) {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<ReactSVG src={nextArrow} style={{ width: 32 + 'px' }} />
		</div>
	);
}


class GlobeSolution extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav1: null,
			nav2: null,
			isDesktop: false, //This is where I am having problems
			isMobile: false, //This is where I am having problems
			pageData: null
		};
		this.updatePredicate = this.updatePredicate.bind(this);
	}

	createMarkup(data) {
		return { __html: data };
	}

	componentDidMount() {
		window.scroll(0, 0);
		_target = document.getElementsByClassName('headerWrap')[0];
		_target.classList.add('solutionHead');

		this.setState({
			nav1: this.slider1,
			nav2: this.slider2
		});

		this.updatePredicate();
		window.addEventListener("resize", this.updatePredicate);

		axios.post('/solution_menus', { section: 'ALL' }).then(response => {
			this.setState({ pageData: response.data.page });
		})
	}

	componentWillUnmount() {
		_target.classList.remove('solutionHead');

		window.removeEventListener("resize", this.updatePredicate);
	}

	updatePredicate() {
		this.setState({ isDesktop: window.innerWidth > 992 });
		this.setState({ isMobile: window.innerWidth > 768 && window.innerWidth < 992 });
	}

	onSelectSlick = (e, index, path) => {
		const _target = e.target.closest(".slick-slide");
		const _targetLeft = parseInt(_target.getBoundingClientRect().x);
		const _targetWidth = _target.clientWidth;
		const _targetEnd = (_targetLeft + _targetWidth);
		const _windowWidth = window.outerWidth;
		if (_targetEnd > _windowWidth) {
			this.slider2.slickGoTo(index);
		} else if (_targetLeft < 0) {
			this.slider2.slickPrev();
		}
		else {
			this.props.history.push(path);
		}

	}

	onMouseEnterSlick = (index) => {
		this.slider1.slickGoTo(index);
		// this.slider2.slickGoTo(index);
	}

	onSliderChange = (index) => {
		this.slider1.slickGoTo(index);
	}

	render() {
		const isDesktop = this.state.isDesktop;
		const isMobile = this.state.isMobile;
		// bg slider
		const slideBG = {
			slidesToShow: 1,
			arrows: false,
			fade: true,
			infinite: false,
			swipe: false,
			initialSlide: 0,
			centerMode: false,
		}

		// content slider
		const slideContent = {
			slidesToShow: 1,
			swipeToSlide: false,
			focusOnSelect: false,
			centerMode: true,
			centerPadding: '80px',
			infinite: false,
			arrows: true,
			initialSlide: 0,
			variableWidth: true,
			draggable: false,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						// slidesToShow: 3,
						centerPadding: '0',
						// centerMode: true,
					}
				},
				{
					breakpoint: 992,
					settings: {
						// slidesToShow: 2,
						centerPadding: '0',
					}
				},
				{
					breakpoint: 768,
					settings: {
						focusOnSelect: true,
						slidesToShow: 1,
						centerPadding: '0',
						centerMode: false,
					}
				}
			]
		}

		let solutionSliderImages = null;
		let solutionSlider = null;
		let solutionTitleSection = null;

		if (this.state.pageData !== null) {
			solutionTitleSection = (
				<article className="py-3 px-xl-3 fixedWidth" onClick={(event) => this.onSelectSlick(event, 0)} onMouseMove={() => this.onMouseEnterSlick(0)}>
					<Fade bottom>
						<span className="text-white d-block display-3 mb-lg-5 mb-4">{this.state.pageData.menu.title}</span>
						<h5 className="title text-white mb-0 font-family-body">{this.state.pageData.menu.description}</h5>
						{/* <Link to="/story" className="d-block play-link">
							<span className="playBtnWrap">
								<ReactSVG className="playBtn" src={playBtn} />
								<img className="rounded-circle" src={this.state.pageData.menu.image} />
							</span>
							<h6 className="title text-white mt-3 mb-0 text-center text-uppercase" dangerouslySetInnerHTML={this.createMarkup(this.state.pageData.menu.subtext)}></h6>
						</Link> */}
					</Fade>
				</article>
			);

			solutionSliderImages = this.state.pageData.menu.items.map((item, index) => <img key={'sloutions-slider-image-' + index} alt={item.sub_title} src={item.image} />);
			solutionSlider = this.state.pageData.menu.items.map((item, index) => {
				return (
					<article key={'sloutions-slider-' + index} className="py-3 px-xl-3" onClick={(event) => this.onSelectSlick(event, index, '/solutions/' + item.link)} onMouseMove={() => this.onMouseEnterSlick(index + 1)}>
						<Fade bottom>
							<span className="number text-white d-block font-family-heading">{item.title}</span>
							<h4 className="title text-white mb-4 mb-lg-5 text-uppercase">{item.sub_title}</h4>
							<p>{item.description}</p>
						</Fade>
					</article>
				);
			});
		}

		const solutionPage = this.state.pageData ? (
			<div>
				{/* bg slider start */}
				<Fade>
					<div className="solution-bg-slider">
						<Slider ref={slider => (this.slider1 = slider)} {...slideBG}>
							<div className="bg-primary d-block vw-100 vh-100"></div>
							{solutionSliderImages}
						</Slider>
					</div>
				</Fade>
				{/* bg slider end */}


				<Slider
					asNavFor={this.state.nav1}
					ref={slider => (this.slider2 = slider)}
					afterChange={index => this.onSliderChange(index)}
					{...slideContent}
					className="solution-content-slider">

					{solutionTitleSection}

					{solutionSlider}
					{/* <article className="p-3"></article> */}
					{/* {isDesktop ?
						(
							<>
								<article className="p-3"></article>
							</>
						) : ('')}
					{isMobile ?
						(
							<>
								<article className="p-3"></article>
							</>
						) : ('')} */}
				</Slider>
			</div>
		) : '';

		return (
			<React.Fragment>
				<TitleComponent title="" description="" />
				{solutionPage}
			</React.Fragment>

		)
	}
}

export default GlobeSolution;