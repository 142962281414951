import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import HeroLanding from '../../../components/solution-hero/hero-landing/hero-landing';
import HeroDetail from '../../../components/solution-hero/hero-detail/hero-detail';
import HeroTwoColumn from '../../../components/solution-hero/hero-two-column/hero-two-column';
import TitleComponent from '../../../components/titles/title';

let _target = '';
class SolutionFabricDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageData: null
        };
    }

    componentDidMount() {
        window.scroll(0, 0);
        document.getElementsByTagName('body')[0].classList.add('bg-primary');
        _target = document.getElementsByClassName('headerWrap')[0];
        _target.classList.add('solutionHead', 'bg-primary');

        axios.post('/heroes', { section: 'ALL', page: this.props.match.params.subpage }).then(response => {
            this.setState({ pageData: response.data.page });
        })
    }
    componentWillUnmount() {
        _target.classList.remove('solutionHead', 'bg-primary');
        document.getElementsByTagName('body')[0].classList.remove('bg-primary');
    }
    render() {
        const mainSection = this.state.pageData !== null ? (
            <div className="bg-primary">
                {/* <span className="line line-wwhe"></span> */}
                <HeroLanding data={this.state.pageData.banner}></HeroLanding>
                <HeroDetail data={this.state.pageData.section_1}></HeroDetail>
                <HeroTwoColumn data={this.state.pageData.section_2}></HeroTwoColumn>
            </div>
        ) : '';

        return (
            <React.Fragment>
                <TitleComponent title="" description="" />
                {mainSection}
            </React.Fragment>
        )
    }
}

export default withRouter(SolutionFabricDetails);