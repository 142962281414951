import * as actionTypes from '../actions/actionType';

function uiDataProps(state = {}, action) {
    switch (action.type) {
        case actionTypes.HEADER_CLOSE_ACTION:
            return {
                ...state,
                'header_close_action': action.details,//detail.data.data    
                validate: true
            }
            break;
        case actionTypes.LANDING_SCROLL_ACTION:
            return {
                ...state,
                'landing_scroll_action': action.details,//detail.data.data    
                validate: true
            }
        default: return state
    }
}

export default uiDataProps;