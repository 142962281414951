import React, { Component } from 'react';
import { Container, Row, Col, Accordion, Button } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './solution-accordion.scss';
import plus from '../../../assets/imgs/icons/plus.svg';
import Fade from 'react-reveal/Fade';

class SolutionAccordion extends Component {

    /**
     * **triggerAccordian** 
     * bootstrap accordian no event for active button.
     * this function handling to active accordian button with accordian style.
     */
    triggerAccordian = (e) => {
        const _targetElment = e.target;
        const _fixdParent = _targetElment.closest('.pmAccordion');
        if (_fixdParent.classList.contains('activeBtn')) {
            this.removeClassFromAll(document.getElementsByClassName('activeBtn'));
        } else {
            this.removeClassFromAll(document.getElementsByClassName('activeBtn'));
            _fixdParent.classList.add('activeBtn');
        }
    }
    /**
     * **removeClassFromAll**
     * this function we should need to remove all 
     * activeBtn class form sibling accordian button
     * call it from **triggerAccordian** function
     */
    removeClassFromAll = (_targetElements) => {
        const _targetElementsLength = _targetElements.length;
        if (_targetElementsLength > 0) {
            for (let i = 0; i < _targetElementsLength; i++) {
                _targetElements[i].classList.remove('activeBtn');
            }
        }
    }

    render() {
        return (
            <section>
                <Container className="section-padding pb-0">
                    {/* heading */}
                    <header className="mb-3 mb-md-5">
                        <Fade bottom>
                            <h3 className="display-4 mb-0">{this.props.data.title}</h3>
                        </Fade>
                    </header>

                    <Row>
                        <Col md={5}>
                            <Fade bottom>
                                <span className="d-block h1 font-family-body">{this.props.data.description}</span>
                            </Fade>
                        </Col>

                        {/* accordion start */}
                        <Col md={{ span: 6, offset: 1 }}>
                            <Fade bottom>
                                <Accordion defaultActiveKey={0}>
                                    {/* dynamic accordion item */}
                                    {this.props.data.accordions.map((item, index) => (
                                        <article key={'faq-accordian-' + index}>
                                            <Accordion.Toggle onClick={this.triggerAccordian.bind(this)} as={Button} variant="link" block
                                                className={'pmAccordion px-0 py-3 font-family-body text-medium border-bottom d-flex justify-content-between text-left ' + (index == 0 ? 'activeBtn' : '')} eventKey={index}>
                                                <span className="text-initial">{item.acc_title}</span>
                                                <ReactSVG src={plus} style={{ stroke: '#000000', width: 20 + 'px', margin: '2px 0 0 0' }} />
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={index}>
                                                <div className="pt-2 pb-4 border-bottom border-primary">
                                                    <p className="mb-0 text-primary">{item.acc_description}</p>
                                                </div>
                                            </Accordion.Collapse>
                                        </article>
                                    ))}
                                </Accordion>
                            </Fade>
                        </Col>
                        {/* accordion end */}
                    </Row>
                </Container >
            </section>
        )
    }
}

export default SolutionAccordion;