import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './solution-details.scss';
import Fade from 'react-reveal/Fade';

class SolutionDetails extends Component {
    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section>
                <Container className="section-padding pb-0">
                    {/* heading */}
                    <header className="mb-3 mb-md-5">
                        <Col xl={7} lg={5} md={6} className="p-0">
                            <Fade bottom>
                                <h2 className="display-1 mb-0">{this.props.data.title}</h2>
                            </Fade>
                        </Col>
                    </header>
                    <Row>
                        <Col md={5}>
                            <Fade bottom>
                                <span className="d-block h1 font-family-body">{this.props.data.sub_title}</span>
                            </Fade>
                        </Col>
                        <Col md={{ span: 6, offset: 1 }} className="mb-3 mb-md-5">
                            <Fade bottom cascade>
                                <p dangerouslySetInnerHTML={this.createMarkup(this.props.data.description)}></p>
                            </Fade>
                        </Col>
                        <Col md={12}>
                            <Fade bottom>
                                <img src={this.props.data.image} alt="" className="img-fluid" />
                            </Fade>
                        </Col>
                    </Row>
                </Container >
            </section>
        )
    }
}

export default SolutionDetails;