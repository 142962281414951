import React, { Component } from 'react';
import { Container, Row, Col, Accordion, Button, Card } from 'react-bootstrap';
import './togather.scss';
import { Link, NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';

class Togather extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="section-padding pt-0 about-togather position-relative">
                <div className="bg-primary position-absolute togather-box d-md-block d-none"></div>
                <Container>
                    <Row>
                        <Col lg={5} md={6} className="togather-title d-flex align-items-center justify-content-center mb-4 mb-md-0 text-md-left text-center">
                            <Fade bottom>
                                <h2 className="d-block display-1 mb-0 text-white" dangerouslySetInnerHTML={this.createMarkup(this.props.data.quotes)}></h2>
                            </Fade>
                        </Col>
                        <Col md={6} lg={{ span: 6, offset: 1 }} className="d-flex align-items-md-center justify-content-md-center flex-column">
                            <Fade bottom>
                                <span className="d-block h2 font-family-body text-primary mb-4">{this.props.data.title}</span>
                            </Fade>
                            <Fade bottom>
                                <span className="d-block">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }

}


export default Togather;