import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './innovation-landing.scss';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import nextArrow from '../../../assets/imgs/icons/next-arrow-white.svg';

class InnovationLanding extends Component {

    createMarkup(data) {
        return { __html: data };
    }
    componentDidUpdate() {
        window.scroll(0, 0);
    }
    render() {
        let navCtrl = null;
        if (this.props.menu) {
            const currentMenuIndex = this.props.menu.map(item => item.link).indexOf(this.props.currentPage);
            const navMenu = [];
            if (currentMenuIndex === this.props.menu.length - 1) {
                navMenu.push(this.props.menu[currentMenuIndex - 1]);
                navMenu.push(this.props.menu[0]);
            } else if (currentMenuIndex === 0) {
                navMenu.push(this.props.menu[this.props.menu.length - 1]);
                navMenu.push(this.props.menu[currentMenuIndex + 1]);
            } else {
                navMenu.push(this.props.menu[currentMenuIndex - 1]);
                navMenu.push(this.props.menu[currentMenuIndex + 1]);
            }

            navCtrl = (
                <div className="control-bar">

                    <Link className="btn btn-primary btn-discover btn-lg" to={this.props.data.category_url}>
                        <Fade bottom>
                            {this.props.data.category_name}
                        </Fade>
                    </Link>

                    <Row noGutters="true" className="align-items-end">
                        <Col>
                            <Link className="btn btn-lg btn-block btn-prev" to={'/innovations/' + navMenu[0].link}>
                                <Fade bottom>
                                    <ReactSVG className="rotate180" src={nextArrow} style={{ width: 32 + 'px', margin: "auto", fill: "#fff" }} />
                                </Fade>
                            </Link>
                        </Col>
                        <Col>
                            <div className="bg-primary text-white">
                                <Fade bottom>
                                    <figure className="mb-0 text-white nxtnav">
                                        <span className="d-block h3 text-white mb-0 nav-menu-title text-uppercase">{navMenu[1].title}</span>
                                        <Link className="justify-content-start btn btn-lg" to={'/innovations/' + navMenu[1].link}><ReactSVG className="" src={nextArrow} style={{ width: 32 + 'px' }} /></Link>
                                    </figure>
                                </Fade>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        }
        return (
            <section className="innovaion-landing bg-primary text-white">

                <Row className=" d-flex align-items-end h-100 position-relative z-index-3" noGutters="true">
                    {/* content start */}
                    <Col className="content">
                        <Fade bottom>
                            <figure className="mb-0 pb-lg-5">
                                <span>{this.props.data.sub_title}</span>
                                <span className=" display-1 d-block text-white mt-3 mb-md-4 w-sm-50" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></span>
                                <span className="lead d-md-block d-none" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description)}></span>
                            </figure>
                        </Fade>
                    </Col>
                    {/* content end */}

                    {/* img start */}
                    <Col className="position-relative h-100">
                        <Fade>
                            <img src={this.props.data.media.images} className="cover-img" alt="" />
                        </Fade>

                        {/* control start */}
                        {navCtrl}
                        {/* control end */}
                    </Col>
                    {/* img start */}

                    {/* for mobile version start */}
                    <Col xs={12}>
                        <Fade bottom>
                            <span className="d-md-none d-block mt-4" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description)}></span>
                        </Fade>
                    </Col>
                    {/* for mobile version start */}
                </Row>
            </section>
        )
    }
}




export default InnovationLanding;