import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './services.scss';
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";

class Services extends Component {

    /**
	 * 
	 * @param {*} touchScroll 
	 * this function handling to detect services section touch scroll top.
	 */
    touchScroll(e) {
        const targetDiv = document.getElementsByClassName('pmIsSticky')[0];
        const targetParent = document.getElementsByClassName('mainContentArea')[0];
        const _header = document.getElementsByClassName('headerWrap')[0];
        const _headerHeight = _header.clientHeight;
        const _targetOfset = targetDiv.offsetTop - _headerHeight;

        const _targetHeight = targetDiv.clientHeight;
        const _offsetNDHeight = _targetOfset + _targetHeight;

        if (window.scrollY > _targetOfset && window.scrollY <= _offsetNDHeight) {
            targetDiv.classList.add('targetDiv');
            targetParent.classList.add('removeOverflow');
        } else {
            targetDiv.classList.remove('targetDiv');
            targetParent.classList.remove('removeOverflow');
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.touchScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.touchScroll);
    }

    render() {
        const settings = {
            infinite: false,
            speed: 800,
            slidesToScroll: 1,
            slidesToShow: 1,
            arrows: false,
            variableWidth: true,
        };
        return (
            <section className="pmIsSticky services position-relative d-md-flex flex-wrap section-padding pt-0">
                <Col md={6} className="px-0 d-md-block d-none">
                    <div className="half-container mr-0 ml-auto">
                        <Row>
                            <Col md={10}>
                                {/* start */}
                                <Fade bottom cascade>
                                    <article className="section-padding">
                                        <h2 className="display-1 mb-5">{this.props.data.left.block1.sub_title}</h2>
                                        <span className="d-block h2 font-family-body mb-4">{this.props.data.left.block1.title}</span>
                                        <p className="mb-0">{this.props.data.left.block1.description}</p>
                                    </article>
                                </Fade>
                                {/* end */}

                                {/* start */}
                                <Fade bottom cascade>
                                    <article className="section-padding pt-0">
                                        <h2 className="display-1 mb-5">{this.props.data.left.block2.sub_title}</h2>
                                        <span className="d-block h2 font-family-body mb-4">{this.props.data.left.block2.title}</span>
                                        <p className="mb-0">{this.props.data.left.block2.description}</p>
                                    </article>
                                </Fade>
                                {/* end */}

                                {/* start */}
                                {/* <Fade bottom cascade>
                                    <article className="section-padding pt-0">
                                        <h2 className="display-1 mb-5">{this.props.data.left.block3.sub_title}</h2>
                                        <span className="d-block h2 font-family-body mb-4">{this.props.data.left.block3.title}</span>
                                        <p className="mb-0">{this.props.data.left.block3.description}</p>
                                    </article>
                                </Fade> */}
                                {/* end */}
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={6} className="p-0">
                    <div className="primary-box bg-primary d-flex flex-column justify-content-center align-items-center text-center text-white w-100 position-sticky top right">
                        <Fade>
                            <figure className="z-index-1 stickyText mb-0">
                                <h2 className="display-1 text-white mb-4 mb-lg-5">{this.props.data.right.title}</h2>
                                <span className="d-block h3 font-family-body text-white  mb-0">{this.props.data.right.sub_title}</span>
                            </figure>
                        </Fade>
                    </div>
                </Col>

                {/* for mobile device start */}
                <Container className="d-block d-md-none">
                    {/* start */}
                    <Fade bottom cascade>
                        <article className="section-padding">
                            <h2 className="display-1 mb-4">{this.props.data.left.block1.sub_title}</h2>
                            <span className="d-block h2 font-family-body mb-4">{this.props.data.left.block1.title}</span>
                            <p className="mb-0">{this.props.data.left.block1.description}</p>
                        </article>
                    </Fade>
                    {/* end */}

                    <Slider {...settings} className="services-carousel">
                        {/* start */}
                        <article className="item bg-primary text-white p-3">
                            <Fade bottom>
                                <h2 className="display-1 mb-3 text-white">{this.props.data.left.block2.sub_title}</h2>
                                <span className="d-block h2 mb-2 text-white">{this.props.data.left.block2.title}</span>
                                <p className="mb-0">{this.props.data.left.block2.description}</p>
                            </Fade>
                        </article>
                        {/* end */}

                        {/* start */}
                        {/* <article className="item bg-primary text-white p-3">
                            <Fade bottom>
                                <h2 className="display-1 mb-3 text-white">{this.props.data.left.block3.sub_title}</h2>
                                <span className="d-block h2 mb-2 text-white">{this.props.data.left.block3.title}</span>
                                <p className="mb-0">{this.props.data.left.block3.description}</p>
                            </Fade>
                        </article> */}
                        {/* end */}
                    </Slider>
                </Container>
                {/* for mobile device end */}

            </section>
        )
    }
}

export default Services;