import React, { Component } from 'react';
import { Container, Row, Col, Accordion, Button, Card } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import downArrow from '../../../assets/imgs/icons/down-arrow.svg';
import './fashion.scss';
import { Link, NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import jeans from '../../../assets/imgs/about/fresh_denim_jeans.jpeg';

class Fashion extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="section-padding pt-0 about-fashion">
                <Container>
                    <Row>
                        <Col md={12} className="border-after">
                            <Fade>
                                <img src={this.props.data.image} className="object-cover banner-img" />
                            </Fade>
                        </Col>
                        <Col md={10} className=" section-padding pb-4 pb-md-5">
                            <Fade bottom>
                                <h2 className="display-1 mb-0">{this.props.data.sub_title}</h2>
                            </Fade>
                        </Col>
                        <Col lg={5} md={6}>
                            <Fade bottom>
                                <span className="h2 font-family-body d-block">{this.props.data.title}</span>
                            </Fade>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }} md={6}>
                            <Fade bottom>
                                <p className="mb-0" >{this.props.data.description}</p>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

            </section>
        )
    }

}

export default Fashion;