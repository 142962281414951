import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './fashion.scss';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

class Fashion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    render() {
        return (
            <div className="h-vh position-relative mh-50vh bg-light overflow-hidden d-flex flex-column align-items-center">
                <Container>
                    <Row>
                        <Col xs={8} sm={6} md={4} className="z-index-1">
                            <div className="mt-5 mb-4 mb-sm-5 mt-md-5">
                                <Fade bottom>
                                    <h2 className="display-4 mb-3 mb-md-5 text-primary">{this.props.data.title}</h2>
                                    <span className="d-block mb-3 mb-md-5">{this.props.data.description}</span>
                                    <Link className="btn btn-primary btn-xl text-uppercase" to={this.props.data.button_link === 'contact' ? '/contact' : '/' + this.props.data.button_link}>{this.props.data.button_label}</Link>
                                </Fade>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div>

                    <Fade bottom>
                        <div className="ws-f-r-img">
                            <div className="fashion-custom-heading">
                                <h2 className="display-4 mb-0 text-white">{this.props.data.sec3_image1alt}</h2>
                            </div>
                            <img src={this.props.data.image[0]} />
                        </div>
                    </Fade>
                    <Fade bottom>
                        <img className="w-25 h-50 h-xs-25 position-absolute right bottom px-0 px-md-3 inline-block object-cover" src={this.props.data.image[1]} />
                    </Fade>
                </div>
            </div>
        )
    }
}


export default Fashion;