import React, { Component } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import InnovationLanding from "../../../components/innovation-detail/innovation-landing/innovation-landing";
import Benefit from "../../../components/innovation-detail/benefit/benefit";
import Timing from "../../../components/innovation-detail/timing/timing";
import InnovationMenu from "../../../components/innovation-detail/innovation-menu/innovation-menu";
import TitleComponent from '../../../components/titles/title';

let _target = '';
class InnovationDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageData: null,
            pageMenu: null,
            currentPage: null
        };
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].classList.add('bg-primary');
        _target = document.getElementsByClassName('headerWrap')[0];
        _target.classList.add('solutionHead', 'bg-primary');
        const { match: { params } } = this.props;

        axios.post('/slideinnovation', { section: 'ALL', page: params.page }).then(response => {
            if (response.data.status == false) {
                this.props.history.push('/404')
            } else {
                this.setState({ currentPage: params.page });
                this.setState({ pageData: response.data.page });
            }
        })

        axios.post('/slideinnovation-links', { section: 'ALL' }).then(response => {
            this.setState({ pageMenu: response.data });
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.page !== prevProps.match.params.page) {
            // this.setState({ pageData: null });
            axios.post('/slideinnovation', { section: 'ALL', page: this.props.match.params.page }).then(response => {
                if (response.data.status == false) {
                    this.props.history.push('/404')
                } else {
                    this.setState({ currentPage: this.props.match.params.page });
                    this.setState({ pageData: response.data.page });
                }
            })
        }
    }

    componentWillUnmount() {
        _target.classList.remove('solutionHead', 'bg-primary');
        document.getElementsByTagName('body')[0].classList.remove('bg-primary');
    }


    render() {
        let innovationDetails = null
        if (this.state.pageData !== null) {
            innovationDetails = (
                <div>
                    {/* <span className="line line-wwi"></span> */}
                    <InnovationLanding data={this.state.pageData.banner_section} menu={this.state.pageMenu} currentPage={this.state.currentPage}></InnovationLanding>
                    <Benefit data={this.state.pageData.section_1}></Benefit>
                    <Timing data={this.state.pageData.section_2}></Timing>
                    <InnovationMenu data={this.state.pageData.section_3} menu={this.state.pageMenu} currentPage={this.state.currentPage}></InnovationMenu>
                </div>
            );
        }
        return (
            <React.Fragment>
                <TitleComponent title="" description="" />
                {innovationDetails}
            </React.Fragment>
        )
    }
}

export default withRouter(InnovationDetails);