import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import './footer.scss';

class MainFooter extends Component {
    createMarkup(data) {
        return { __html: data };
    }

    constructor(props) {
        super(props);
        this.state = {
            getInTouch: null,
            footerNav: null,
            followUs: null
        };
    }

    componentDidMount() {
        axios.get('/above_footer').then(response => {
            this.setState({ getInTouch: response.data });
        })

        axios.get('/footer_menu').then(response => {
            this.setState({ footerNav: response.data.hamburger });
        })

        // axios.get('/social_link').then(response => {
        //     this.setState({ followUs: response.data });
        // })
        // console.log(this.props)
    }


    render() {
        let getInTouchSection = null;
        let footerNavSection = null;
        let followUsSection = null;

        if (this.state.getInTouch !== null) {
            getInTouchSection = (
                <section className="section-padding border-bottom">
                    <Row className="align-items-center">
                        <Col lg={5} md={6}>
                            <Fade bottom>
                                <h2 className="d-block display-1 mb-md-0">{this.state.getInTouch.title}</h2>
                            </Fade>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }} md={6}>
                            <Fade bottom cascade>
                                <span dangerouslySetInnerHTML={this.createMarkup(this.state.getInTouch.description)} className="d-block mb-3 mb-lg-5"></span>
                                {this.props.location.pathname === '/contact' ? '' : (
                                    <Link to={'/' + this.state.getInTouch.button_link} className="btn btn-primary btn-lg">{this.state.getInTouch.button_label}</Link>
                                )}
                            </Fade>
                        </Col>
                    </Row>
                </section>
            )
        }

        if (this.state.footerNav !== null) {
            footerNavSection = this.state.footerNav.map((menu, index) => {
                const subMenus = menu.sub_menu.map((subMenu, indx) => {
                    return <li key={'footer-submenu-' + subMenu.menu_id}><Link to={'/' + subMenu.link} className="text-medium">{subMenu.menu_name}</Link></li>
                });

                return (
                    <Col sm={3} xs={6} className="section-padding menu-item" key={'footer-nav-menu-' + menu.menu_id}>
                        {/* <Fade bottom> */}
                        <span className="d-block text-primary">{menu.menu_name}</span>
                        <ul className="mb-0">
                            {subMenus}
                        </ul>
                        {/* </Fade> */}
                    </Col>
                );
            });
        }

        return (
            <footer className="bg-light main-footer">
                <Container>
                    {/* get option start */}
                    {getInTouchSection}
                    {/* get option end */}

                    <section className="footer-bootm">
                        <Row>
                            {footerNavSection}
                            {this.state.followUs !== null && this.state.followUs.follow_us.length > 0 ? (
                                <Col sm={3} xs={6} className="section-padding menu-item d-flex border-left align-items-sm-center flex-column">
                                    {/* <Fade bottom> */}
                                    <span className="d-block text-primary">{this.state.followUs.follow_us_title}</span>
                                    <ul className="mb-0">
                                        {this.state.followUs.follow_us.map((social, index) =>
                                            <li key={'footer-submenu-follow-' + index}><Link to={'/' + social.social_media_link} className="text-medium">{social.title}</Link></li>
                                        )}
                                    </ul>
                                    {/* </Fade> */}
                                </Col>
                            ) : null}
                        </Row>
                    </section>

                </Container>
            </footer>
        )
    }
}

export default withRouter(connect()(MainFooter));