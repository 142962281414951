import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './eco-system.scss';
import Fade from 'react-reveal/Fade';


class EcoSystem extends Component {


    render() {
        return (
            <section className="an-echo section-padding">
                <Container>
                    <Row className="mb-4 mb-xl-5">
                        <Col md={8}>
                            <Fade bottom>
                                <h2 className="d-block display-1 mb-0">{this.props.data.title}</h2>
                            </Fade>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={5} md={6} className="mb-4 mb-md-0">
                            <Fade bottom>
                                <span className="d-block h2 font-family-body text-primary mb-0">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                        <Col md={6} lg={{ span: 6, offset: 1 }}>
                            <Fade bottom>
                                <span className="d-block">{this.props.data.sub_description}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}




export default EcoSystem;