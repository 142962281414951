import React, { Component } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './event.scss';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";
import play from '../../../assets/imgs/icons/play.svg';
import crossArrow from '../../../assets/imgs/icons/cross.svg';

class EventCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            modalVideoLink: null
        };
        // this.props.data.events.push(this.props.data.events[1]);
        // this.props.data.events.push(this.props.data.events[2]);
    }

    setModalShow = (param, video) => {
        this.setState({ modalShow: param, modalVideoLink: video });
    }

    onMouseEnterSlick = (e, index) => {
        const _target = e.target.closest(".slick-slide");
        const _targetLeft = _target.getBoundingClientRect().x;
        const _targetWidth = _target.clientWidth;
        const _targetEnd = (_targetLeft + _targetWidth);
        const _windowWidth = window.outerWidth;
        if (_targetEnd > _windowWidth) {
            this.slider1.slickNext();
        }
        if (_targetLeft < 0) {
            this.slider1.slickPrev();
        }
        if (index === 0) {
            this.slider1.slickGoTo(index);
        }

        const _nextCurrent = _target.nextSibling;
        if (_nextCurrent) {
            const _nextActive = _nextCurrent.className.indexOf('slick-current');
            if (_nextActive > 0) {
                this.slider1.slickPrev();
            }
        }
    }


    render() {
        const sliderEvent = {
            infinite: false,
            slidesToShow: 2,
            // slidesToScroll: 1,
            initialSlide: 0,
            centerMode: false,
            variableWidth: true,
            speed: 800,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: true,
                    }
                }
            ]
        };

        return (
            <section className="comnSec event section-padding pt-0">
                <Container className="section-padding pb-0 mb-lg-5 mb-4">
                    <Row>
                        <Col lg={5} xs={8}>
                            <Fade bottom>
                                <h3 className="display-4 mb-0">{this.props.data.title}</h3>
                            </Fade>
                        </Col>
                        {/* <Col lg={{ span: 6, offset: 1 }} xs={4} className="text-right">
                            <Fade>
                                <Link to="/home">all events</Link>
                            </Fade>
                        </Col> */}
                    </Row>

                </Container>
                <Container>
                    <Slider ref={slider => (this.slider1 = slider)} {...sliderEvent} className="event-carousel">
                        {/*event item 1 */}
                        {this.props.data.events.map((item, index) => {
                            return (
                                <Link onMouseMove={(event) => this.onMouseEnterSlick(event, index)} to="/home" className="item bg-primary text-white py-md-0 p-4 custom-cursor" key={'home-events-' + index}>
                                    <Row>
                                        <Col className="event-vid-thumb d-none d-md-block">
                                            <Fade fade>
                                                <div className="video-holder">

                                                    {
                                                        item.video !== '' ? (
                                                            <div onClick={() => this.setModalShow(true, item.video)} className="d-block position-relative">
                                                                <span className="play">
                                                                    <ReactSVG src={play} style={{ width: 18 + 'px' }} />
                                                                </span>
                                                                <img src={item.image} alt="" />
                                                            </div>
                                                        ) : (
                                                                <div onClick={() => this.setModalShow(false, item.video)} className="d-block position-relative">
                                                                    <img src={item.image} alt="" />
                                                                </div>
                                                            )
                                                    }

                                                </div>
                                            </Fade>

                                        </Col>
                                        <Col className="date d-flex flex-column justify-content-center">
                                            <Fade>
                                                <span className="d-block display-3 text-white event-date">{item.event_day}</span>
                                                <span className="d-md-block d-none h5 text-white mb-0 text-uppercase">{item.event_month}</span>
                                            </Fade>
                                        </Col>
                                        <Col className="d-md-flex d-none flex-column justify-content-center">
                                            <Fade>
                                                <div className="d-block mb-2 white-space-nowrap"><span className="opacity-5">{item.event_month + ' ' + item.event_day + ' - ' + item.publish_day + ', ' + item.publish_year}</span></div>
                                                <span className="d-block">{item.name}</span>
                                            </Fade>
                                        </Col>

                                        {/* for mobile device */}
                                        <Col className="d-block d-md-none opacity-5">
                                            <Fade>
                                                <span className="d-block mb-0">{item.publish_day_name}</span>
                                                <span className="d-block mb-0">{item.event_month + ' ' + item.event_day + ' - ' + item.publish_day + ', ' + item.publish_year}</span>
                                            </Fade>
                                        </Col>
                                        <Col xs={12} className="d-block d-md-none mt-3">
                                            <Fade>
                                                <span className="d-block">{item.name}</span>
                                            </Fade>
                                        </Col>
                                        {/* for mobile device */}
                                    </Row>
                                </Link>
                            );
                        })}
                    </Slider>
                </Container>
                {/* video modal */}
                <VideoModal
                    videolink={this.state.modalVideoLink}
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                />
            </section>

        )
    }
}

function VideoModal(props) {
    return (
        <Modal {...props} size="lg" centered>
            <Button onClick={props.onHide} className="modal-close">
                <ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
            </Button>
            <Modal.Body>
                <iframe id="ytplayer" type="text/html" width="100%" height="405"
                    src={props.videolink + '?autoplay=1&disablekb=1'}
                    frameBorder="0" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
    );
}

export default EventCarousel;