import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './challenge.scss';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';


class Challenge extends Component {

    render() {
        return (
            <section className="comnSec challenge">
                <Container className="section-padding">
                    <Row>
                        <Col lg={5} md={6}>
                            <Fade bottom>
                                <h3 className="display-4 mb-4 mb-md-0">{this.props.data.title}</h3>
                            </Fade>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }} md={6}>
                            <Fade bottom cascade>
                                <figure className="mb-0">
                                    <span className="d-block h3 font-family-body mb-0 des">{this.props.data.sub_title}</span>
                                    {/* <Link to={'/' + this.props.data.links.link} className="h4 mb-0">{this.props.data.links.label}</Link> */}
                                </figure>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}




export default Challenge;