import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import ReactSVG from 'react-svg';
import './landing.scss';
import { Link } from "react-router-dom";

/*--for solutions advice page--*/
import downArrow from '../../../assets/imgs/icons/down-arrow.svg';

class HomeLanding extends Component {
    scrollDown = () => {
        const _targetSection = document.getElementsByClassName('globe-story')[0];
        const _targetOffsetTop = _targetSection.offsetTop;
        const _headerHeight = document.getElementsByClassName('main-header')[0].clientHeight;

        window.scroll({
            top: (_targetOffsetTop - _headerHeight),
            behavior: 'smooth'
        });
    }

    createMarkup(data) {
        return { __html: data };
    }

    componentDidMount() {
        setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 200)
    }


    render() {

        return (

            <section className="comnSec landing-home section-padding pt-0">
                <div className="h-vh position-relative mob-banner-height">
                    <Fade>
                        <img src={this.props.data.media.images[0].image_1} className="up-line d-sm-block d-none cover-img animated fadeIn" alt="" />
                    </Fade>

                    {/* for mobile device */}
                    <div className="embed-responsive embed-responsive-4by3 d-sm-none up-line">
                        <img src={this.props.data.media.images[0].image_1} className="embed-responsive-item cover-img animated fadeIn" alt="" />
                    </div>

                    {/* content start */}
                    <Fade bottom>
                        <div className="position-absolute bottom bg-primary text-white p-4 p-xl-5 primary-box content-box">
                            <div className="p-xl-5">
                                <figure className="mb-0 text-white pb-3">
                                    <span className="d-block display-1 text-white mb-3" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></span>
                                    <span className="lead w-75 w-xs-90 pr-0 pr-sm-5 des">{this.props.data.description}</span>
                                </figure>
                            </div>
                            <div className="d-flex primary-box-buttons">
                                <Link to={'/' + this.props.data.links[1].link} className="h4 py-3 px-0 text-uppercase bg-white btn-banner mb-0 justify-content-md-start justify-content-center">{this.props.data.links[1].label}</Link>
                                <Link to={'/' + this.props.data.links[0].link} className="h4 text-uppercase btn-banner mb-0 text-white">{this.props.data.links[0].label}</Link>
                            </div>
                            {/* <Link className="d-none d-md-flex h3 position-absolute bottom right py-3 px-5 mb-0 bg-white text-primary landing-button align-items-center justify-content-center" to="/solutions">DISCOVER</Link> */}
                        </div>
                    </Fade>
                    {/* content end */}

                    {/* content start */}
                    {/* <Container className="content position-relative z-index-1">
                        <Row>
                            <Col md={5}>
                                <figure className="pb-0 mb-md-3 mb-4">
                                    <Fade left>
                                        <h2 className="display-1 mb-4 mb-lg-5" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></h2>
                                    </Fade>
                                    <p className="mb-md-3 mb-4">{this.props.data.description}</p>
                                    <Link to={'/' + this.props.data.links[0].link} className="h4 mr-4 text-uppercase">{this.props.data.links[0].label}</Link>
                                    <Link to={'/' + this.props.data.links[1].link} className="h4 text-uppercase">{this.props.data.links[1].label}</Link>
                                </figure>
                            </Col>
                        </Row>
                    </Container> */}
                    {/* content end */}
                </div>
                {/* solutions start */}
                {/* <Link to="/solutions" className="landing-solutions">
                    <Fade right>
                        <Row>
                            {this.props.data.solutions.map((item, index) => {
                                return (
                                    <Col lg={3} md xs={9} className="bg-primary text-white d-flex align-items-md-end pb-md-5 pt-3 pb-4 item" key={'home-solutions-' + index}>
                                        <article className="px-lg-2 py-lg-3">
                                            <span className="text-white d-block font-family-heading num mb-md-0 mb-3">{item.title}</span>
                                            <h4 className="title text-white mb-lg-4 mb-3 text-uppercase">{item.sub_title}</h4>
                                            <p className=" mb-md-3 mb-0 des"><span className="opacity-6">{item.description}</span></p>
                                        </article>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Fade>
                </Link> */}
                {/* solutions end */}

                <Link to="/home" onClick={this.scrollDown} className="d-md-block d-none"><ReactSVG className="pulse-arrow m-auto" src={downArrow} style={{ width: 16 + 'px' }} /></Link>
            </section>
        )
    }
}




export default HomeLanding;