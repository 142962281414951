import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.scss';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import pages
import MainHeader from './components/header/header';

import NotFound from "./containers/notFound/notFound";
import GlobeHome from './containers/pages/home/home';
import GlobeAbout from './containers/pages/about/about';
import GlobeSolution from './containers/pages/solution/solution';
import GlobeSolutionDetail from './containers/pages/solution-detail/solution-detail';
import GlobeContact from './containers/pages/contact/contact';
import InnovationDetails from './containers/pages/innovation-details/innovation-details';
import SolutionHero from './containers/pages/solution-hero/solution-hero';
import GlobeStory from './containers/pages/story/story';
import SolutionFabricDetails from './containers/pages/solution-fabric-details/solution-fabric-details';

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentPage: 0,
			width: props.width
		};
	}
	componentDidMount() {
		this.getDetactBrowserAndDevice();
	}
	getDetactBrowserAndDevice() {
		const _targetBody = document.getElementsByTagName('body')[0];
		const isChrome = (navigator.userAgent.indexOf('Chrome') > 0)
		const isMacOs = (navigator.userAgent.indexOf('Mac') > 0);
		const isAndroidOs = (navigator.userAgent.indexOf('Android') > 0);

		if(isChrome) _targetBody.classList.add('chrome');
		if(isMacOs) _targetBody.classList.add('mac-os');
		if(isAndroidOs) _targetBody.classList.add('android-os');

	}
	componentWillMount() {
		this.setState({ width: window.innerWidth });
	}

	render() {
		// var userType = localstorage.get('user_type');

		if ('isError' in this.props && this.props.isError == true && 'error' in this.props && this.props.error) {
			toast.error(this.props.error.message, {
				position: toast.POSITION.TOP_RIGHT
			});
		}

		let routes = (
			<Switch>
				<Route
					path="/home"
					render={(props) => <GlobeHome {...props} width={this.state.width} />}
				/>
				<Route
					path="/about"
					render={(props) => <GlobeAbout {...props} width={this.state.width} />}
				/>
				<Route
					exact
					path="/solutions"
					render={(props) => <GlobeSolution {...props} width={this.state.width} />}
				/>
				<Route
					exact
					path="/solutions/:page(advice|innovation|marketing|design|development|logistics|finance)"
					render={(props) => <GlobeSolutionDetail {...props} width={this.state.width} />}
				/>
				<Route
					exact
					path="/solutions/:page(advice|innovation|marketing|design|development|logistics|finance)/hero"
					render={(props) => <SolutionHero {...props} width={this.state.width} />}
				/>
				<Route
					exact
					path="/solutions/:page(advice|innovation|marketing|design|development|logistics|finance)/:subpage"
					render={(props) => <SolutionFabricDetails {...props} width={this.state.width} />}
				/>
				<Route
					path="/story"
					render={(props) => <GlobeStory {...props} width={this.state.width} />}
				/>
				<Route
					path="/contact"
					render={(props) => <GlobeContact {...props} width={this.state.width} />}
				/>
				<Route
					path="/innovations/:page"
					render={(props) => <InnovationDetails {...props} width={this.state.width} />}
				/>
				<Route
					path='/404' component={NotFound}
				/>
				<Redirect exact from="/" push to="/home" />
				<Redirect from='*' to='/404' />
			</Switch>
		);

		return (
			<div className="mainContentArea">
				{/* header start */}
				<MainHeader headerProps={this.state.currentPage}></MainHeader>
				{/* header end */}
				{routes}
				<ToastContainer />
			</div>
		);
	}
}

const mapStateToProps = state => {

	return {
		error: state.auth.error,
		isError: state.auth.isError
	};
}


export default withRouter(connect(mapStateToProps, null)(App));

// export default withRouter(App);