import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './innovation.scss';
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";
import nextArrow from '../../../assets/imgs/icons/next-arrow.svg';
import prevArrow from '../../../assets/imgs/icons/prev-arrow.svg';

import { Link, NavLink } from "react-router-dom";
// SamplePrevArrow
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="prev-container">
            <Container className="position-relative">
                <div
                    className={className}
                    onClick={onClick}
                ><ReactSVG src={prevArrow} style={{ width: 32 + 'px' }} /></div>
            </Container>
        </div>
    );
}

// SampleNextArrow
function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <Container className="position-relative">
            <div
                className={className}
                onClick={onClick}
            ><ReactSVG src={nextArrow} style={{ width: 32 + 'px' }} /></div>
        </Container>
    );
}

class Innovation extends Component {

    createMarkup(data) {
        return { __html: data };
    }


    render() {
        const sliderInnovation = {
            infinite: false,
            slidesToShow: 3,
            speed: 800,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                        variableWidth: true,
                        // centerMode: true
                    }
                }
            ]
        };

        return (
            <section className="comnSec innovation section-padding">
                <Container className="section-padding pt-0 position-relative">
                    <Row>
                        <Col lg={5} md={6}>
                            <Fade bottom>
                                <h3 className="display-4 mb-md-0 mb-4" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></h3>
                            </Fade>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }} md={6}>
                            <Fade bottom>
                                <p className="mb-0" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description)}></p>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Slider {...sliderInnovation} className="innovation-carousel">
                        {/* WISER WASH start */}
                        {this.props.data.links.map((item) => {
                            return (
                                <Link key={'home-innovation-slider-' + item.ID} to={'/innovations/' + item.link} className="item bg-primary custom-cursor d-flex align-items-center">
                                    <Fade>
                                        <img src={item.image} />
                                    </Fade>
                                    <figure className="text-white mb-0 w-100">
                                        <Fade bottom>
                                            <h3 className="display-4 text-white" dangerouslySetInnerHTML={this.createMarkup(item.title)}></h3>
                                            <h4 className="text-white mb-4" dangerouslySetInnerHTML={this.createMarkup(item.sub_title)}></h4>
                                            <p className="mb-0 des" dangerouslySetInnerHTML={this.createMarkup(item.description)}></p>
                                            <span className="mb-0 des-mob" dangerouslySetInnerHTML={this.createMarkup(item.description)}></span>
                                            <span className="mb-0 mt-4 text-uppercase more">More</span>
                                        </Fade>
                                    </figure>
                                </Link>
                            );
                        })}
                    </Slider>
                </Container>
            </section>
        )
    }
}

export default Innovation;