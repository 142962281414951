import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './short-description.scss';
import Fade from 'react-reveal/Fade';

class ShortDetail extends Component {
    render() {
        return (
            <section className="position-relative short-description border-top border-bottom">
                <img src={this.props.data.image} className="wg-rt-img object-cover" />
                <Container className="section-padding">
                    <Row>
                        <Col sm={6} md={5} className="py-0 py-sm-4 py-md-5">
                            <Fade bottom cascade>
                                <article className="py-0 py-sm-4 py-md-5">
                                    <span className="d-block text-primary mb-4">{this.props.data.sub_title}</span>
                                    <span className="d-block h2 font-family-body mb-4">{this.props.data.title}</span>
                                    <p className="mb-0">{this.props.data.description}</p>
                                </article>
                            </Fade>
                        </Col>
                    </Row>
                </Container >
            </section>
        )
    }
}

export default ShortDetail;