import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './solutions.scss';
import { Link } from "react-router-dom";
import play from '../../../assets/imgs/icons/play.svg';
import Slider from "react-slick";
import crossArrow from '../../../assets/imgs/icons/cross.svg';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Axios from 'axios';


class Solution extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            allSolutions: null
        };
    }

    componentDidMount() {
        Axios.post('/related_solutions', { section: 'ALL' }).then(response => {
            this.setState({ allSolutions: response.data });
        })
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    render() {
        const sliderSolutions = {
            className: "center",
            infinite: false,
            slidesToShow: 7,
            slidesToScroll: 1,
            speed: 800,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        arrows: false
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: false,
                        variableWidth: true
                    }
                },
            ]
        };

        return (
            <section className="solutions section-padding pt-0">
                <Container className="section-padding pt-0 section-border section-border-after">
                    <Row className="section-margin-b">
                        <Col md={5}>
                            <Fade bottom>
                                <h3 className="display-4 mb-3 mb-md-0">{this.props.data.title}</h3>
                            </Fade>
                        </Col>
                        <Col md={{ span: 6, offset: 1 }}>
                            <Fade>
                                <p className="mb-0">{this.props.data.description}</p>
                            </Fade>
                        </Col>
                    </Row>
                    {this.state.allSolutions !== null ? (
                        <Slider {...sliderSolutions} className="solutions-carousel text-center">
                            {this.state.allSolutions.map((item, index) => {
                                return (
                                    <article className="item" key={'solution-details-solutions-' + index}>
                                        <span className="d-block item-img mb-3">
                                            {item.details.image ? (
                                                <Fade>
                                                    <img src={item.details.image} alt="" />
                                                </Fade>
                                            ) : ''}

                                            <Zoom cascade>
                                                <span className="font-family-heading text-white count d-none d-sm-block">{item.details.sub_title}</span>
                                            </Zoom>
                                            <Link to={item.link === 'contact' ? '/contact' : '/' + item.link} onClick={() => this.setModalShow(true)} className="action">
                                                <span className="play">
                                                    <ReactSVG src={play} style={{ width: 22 + 'px' }} />
                                                </span>
                                            </Link>
                                        </span>
                                        <figure className="mb-0">
                                            <Fade>
                                                <span className="h2 text-primary count d-block d-sm-none mb-0">{item.details.sub_title}</span>
                                                <h2 className="text-primary mb-0 text-uppercase">{item.details.title}</h2>
                                            </Fade>
                                        </figure>
                                    </article>
                                )
                            })}
                        </Slider>
                    ) : ''}
                </Container>

                {/* video modal */}
                <VideoModal
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                />

            </section>
        )
    }
}

function VideoModal(props) {
    return (
        <Modal {...props} size="lg" centered>
            <Button onClick={props.onHide} className="modal-close">
                <ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
            </Button>
            <Modal.Body>
                <iframe id="ytplayer" title="ytplayer" type="text/html" width="100%" height="405"
                    src="https://www.youtube.com/embed/b-Tprb8iRHQ?autoplay=1&disablekb=1"
                    frameBorder="0" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
    );
}

export default withRouter(Solution);