import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ReactSVG from 'react-svg';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../assets/imgs/logo.svg';
import userIcon from '../../assets/imgs/icons/user.svg';
//import share from '../../assets/imgs/icons/share.svg';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import AnimateHeight from 'react-animate-height';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import './header.scss';
import MenuIcon from '../../assets/imgs/icons/menu.svg';


class MainHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            network: '',
            height: 0,
            menuData: null,
            followUs: null,
            prevPath: '',
        };
        this._pageScroller = null;
        this.myRef = React.createRef();
        this.touchScroll = this.touchScroll.bind(this);
    }

    onSelect = (val) => {
        if (this.state.height !== 0) { this.setState({ height: 0 }) }
        document.getElementsByTagName('body')[0].classList.remove('navOpen');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            const pathStringArr = this.props.location.pathname.split('/');
            const isMainRoot = pathStringArr.indexOf('innovations');
            if (isMainRoot > 0) {
                this.setState({ prevPath: '/home' })
            } else {
                this.setState({ prevPath: this.props.location })
            }

        }
    }

    toggle = () => {
        const { height } = this.state;
        this.setState({
            height: height === 0 ? '100%' : 0,
        });

        if (this.state.height === 0) {
            setTimeout(() => {
                document.getElementsByTagName('body')[0].classList.add('navOpen');
                // window.dispatchEvent(new Event('resize'));
                this.checkReactReaval();
            }, 500);
        } else {
            document.getElementsByTagName('body')[0].classList.remove('navOpen');
        }
    };


    /**
     * 
     * @param {*} touchScroll 
     * this function handling after scroll down header fixed with a background color.
     */
    touchScroll(e) {
        const _scrollHeight = window.scrollY;
        const _targetElement = document.getElementsByClassName('header-wrapper')[0];
        const _hedaerHeight = _targetElement.clientHeight;
        if (_scrollHeight >= 10) {
            _targetElement.classList.add('sticky');
        } else {
            _targetElement.classList.remove('sticky');
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.touchScroll);

        axios.get('/menu').then(response => {
            this.setState({ menuData: response.data });
        });
        // axios.get('/social_link').then(response => {
        //     this.setState({ followUs: response.data });
        // })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.touchScroll);
    }

    /**
	 * this function added for mac safari browser bellow 12.1 react reveal not work.
	 * we added a class in body by this class we set custtom react reveal fadein and fadeinUp aniumation for animated element.
	 * we call this function by checking if react real class opacity is 0 then add the class. 
	 * call the function in pageonchange function when section scroll currenty section is 2.
	 * and add a **firstReavel** class in scetion 2 for one react reveal animated element.
	 */
    checkReactReaval() {
        const _ReavelOpacity = document.getElementsByClassName('firstReavel')[0].style.opacity;
        if (_ReavelOpacity === 0 || !_ReavelOpacity) {
            document.getElementsByTagName('body')[0].classList.add('forceReavel');
        }
    }

    // getInitialState = () => {
    //     return { showMenu: false };
    // }
    // onClick = () => {
    //     this.setState({ showMenu: true });
    // }

    toggleMenu() {
        const element = document.querySelector("#menuWrap");
        console.log(element);

        // element[0].classList.add('show');

        if (element.classList.contains("show")) {
            element.classList.remove('show');
        } else {
            element.classList.add('show');
        }
    };

    render() {
        let navMenu = null;
        let hamburgerMenus = null;

        if (this.state.menuData !== null) {
            navMenu = this.state.menuData.menus.map((menu) => {
                return (
                    <li className="nav-item d-none d-md-block" key={'nav-menu-' + menu.menu_id}>
                        <Link to={'/' + menu.link} className="nav-link active" onClick={() => this.onSelect('/' + menu.link)}>{menu.menu_name}</Link>
                    </li>
                )
            })

            if (this.state.menuData.hamburger !== null || this.state.menuData.hamburger.length > 0) {
                hamburgerMenus = this.state.menuData.hamburger.map((menu) => {
                    let subMenus = menu.sub_menu.map((subMenu) => {
                        return <li className="display-3" key={'sub-menu-' + subMenu.menu_id}><Link className="custom-cursor" to={'/' + subMenu.link} onClick={() => this.onSelect('/' + subMenu.link)}>{subMenu.menu_name}</Link></li>;
                    });

                    return (
                        <Row className="section-padding pb-0" key={'hamburger-menu-' + menu.menu_id}>
                            <Col lg={5} sm={6}>
                                <Fade bottom>
                                    <h2 className="firstReavel display-4 mb-2 mb-md-3 mb-lg-5 text-white menu-title">
                                        <Link to={'/' + menu.link} className="main-link custom-cursor" onClick={() => this.onSelect('/' + menu.link)}>{menu.menu_name}</Link>
                                    </h2>
                                    <p className="text-white d-none d-sm-block">{menu.menu_description}</p>
                                </Fade>
                            </Col>
                            <Col lg={{ span: 6, offset: 1 }} sm={6}>
                                <Fade bottom cascade>
                                    <ul className="list-mainmenu mb-0 px-md-3 px-xl-0 mb-0">
                                        {subMenus}
                                    </ul>
                                </Fade>
                            </Col>
                        </Row>
                    )
                })
            }
        }
        return (
            <div>
                <Container fluid="true" className="headerWrap position-fixed z-index-4 header-wrapper overflow-hidden">
                    <header className="main-header">
                        <Row className="align-items-center" noGutters="true">
                            <Col className="logo">
                                <Link to="/home" onClick={() => this.onSelect('/home')} className="d-block">
                                    <ReactSVG src={logo} style={{
                                        width: 44 + 'px', 'fill': '#fff'
                                    }} />
                                </Link>
                            </Col>
                            <Col className="menu-right d-flex align-items-center justify-content-end">
                                <div className="menuWrap" id="menuWrap">
                                    <nav className={'menu ' + (this.state.height !== 0 ? 'invisible' : '')}>
                                        <ul className="nav justify-content-end">
                                            {navMenu}
                                            {/* <li className="nav-item">
                                                <Link to="/home" className="nav-link loginLink">
                                                    <ReactSVG className="headrSvg mr-4" src={userIcon} style={{ width: 16 + 'px' }} />
                                                    Login</Link>
                                            </li> */}
                                        </ul>
                                    </nav>
                                </div>

                                {/* onclick function for menu nav button onClick={this.toggleMenu} */}
                                <Link to="/solutions" id="menuNav">
                                    <ReactSVG src={MenuIcon} style={{
                                        width: 28 + 'px', 'fill': '#fff'
                                    }} />
                                </Link>
                                {/* menu */}
                                <a id="closeNav" onClick={this.toggle} className="closeNavBtn">
                                    <div className={'nav-icon3 ' + (this.state.height !== 0 ? 'open' : '')}>
                                        <span></span><span></span>
                                        <span></span><span></span>
                                    </div>
                                </a>
                                <Link to={this.state.prevPath} onClick={() => this.onSelect(this.state.prevPath)} className="closeNavBtn open ml-4 hide">
                                    <div className='nav-icon3 open'>
                                        <span></span><span></span>
                                        <span></span><span></span>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </header>
                </Container>
                {/* <AnimateWidth duration={400} width={this.state.width ? this.state.width : 0} className="aaa">
                    <div>sudip</div>
                </AnimateWidth> */}
                {/* menu start */}
                <AnimateHeight duration={400} height={this.state.height ? this.state.height : 0} className="main-menu bg-primary">
                    <div className="menu-content d-flex flex-column vh-100 scroll-y scroll-x-hidden">
                        {/* <span className="line line-wwm d-sm-block d-none"></span> */}
                        <Container className="pb-6 pb-xl-0">
                            {hamburgerMenus}
                            {this.state.followUs !== null ? (
                                <Row className="section-padding">
                                    <Col lg={5} sm={6}>
                                        <Fade bottom>
                                            <h2 className="display-4 mb-2 mb-md-4 mb-lg-5 text-white menu-title">{this.state.followUs.follow_us_title}</h2>
                                        </Fade>
                                    </Col>
                                    <Col lg={{ span: 6, offset: 1 }} sm={6}>
                                        <Fade bottom cascade>
                                            <ul className="list-mainmenu mb-0 px-md-3 px-xl-0 mb-0">
                                                {this.state.followUs.follow_us.map((social, index) =>
                                                    <li className="display-4" key={'header-submenu-follow-' + index}><Link to={'/' + social.social_media_link} onClick={() => this.onSelect('/' + social.social_media_link)}>{social.title}</Link></li>
                                                )}
                                            </ul>
                                        </Fade>
                                    </Col>
                                </Row>
                            ) : null}
                        </Container>
                    </div>
                </AnimateHeight>
                {/* menu end */}
            </div>
        )
    }
}


const mapStateToProps = state => {
    //Manp the propps for local state
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainHeader));