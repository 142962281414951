import React, { Component } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './hero-landing.scss';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import play from '../../../assets/imgs/icons/play.svg';
import crossArrow from '../../../assets/imgs/icons/cross.svg';
class HeroLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }
    render() {
        return (
            <section className="hero-landing d-md-flex align-items-md-center position-relative">
                {/* video */}
                <div className="video-holder">
                    <Fade>
                        {
                            this.props.data.video_link !== '' ? (
                                <Link to="#" onClick={() => this.setModalShow(true)} className="video-link d-block">

                                    <span className="play">
                                        <ReactSVG src={play} style={{ width: 18 + 'px' }} />
                                    </span>
                                    <img src={this.props.data.image} alt="" className="cover-img" />
                                </Link>
                            ) : (
                                    <div className="video-link d-block">
                                        <img src={this.props.data.image} alt="" className="cover-img" />
                                    </div>
                                )
                        }
                    </Fade>

                    {/* control */}
                    <div className="control-bar d-md-flex d-none justify-content-end">
                        <Link className="d-block btn btn-primary btn-discover btn-lg" to={this.props.data.category_url}>
                            <Fade bottom>{this.props.data.category_name}</Fade>
                        </Link>
                    </div>
                </div>

                {/* content */}
                <Container className="content">
                    <Row>
                        <Col md={{ span: 5, offset: 7 }}>
                            <Fade bottom>
                                <span className="d-block mb-4 text-white">{this.props.data.title}</span>
                                <h2 className="display-1 mb-4 text-white">{this.props.data.sub_title}</h2>
                                <span className="d-block h3 font-family-body mb-0 text-white">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                {/* video modal */}
                <VideoModal
                    videolink={this.props.data.video_link}
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                />
            </section >
        )
    }
}

function VideoModal(props) {
    return (
        <Modal {...props} size="lg" centered>
            <Button onClick={props.onHide} className="modal-close">
                <ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
            </Button>
            <Modal.Body>
                <iframe id="ytplayer" type="text/html" width="100%" height="405"
                    src={props.videolink + '?autoplay=1&disablekb=1'}
                    frameBorder="0" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
    );
}

export default HeroLanding;