import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import './fabrics.scss';
import { Link } from "react-router-dom";
import crossArrow from '../../../assets/imgs/icons/cross.svg';
import Fade from 'react-reveal/Fade';

class Fabrics extends Component {
    createMarkup(data) {
        return { __html: data };
    }

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            currentPage: ''
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    componentDidMount() {
        this.setState({ currentPage: this.props.match.params.page });
    }

    render() {
        return (
            <Container className={"fabrics section-padding section-border section-border-before " + (this.state.currentPage == 'development' ? 'pb-0' : '')}>
                <Row>
                    <Col sm={5}>
                        <Fade bottom>
                            <h2 className="display-4 mb-4 text-primary">{this.props.data.title}</h2>
                            <p className="" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description)}></p>
                        </Fade>
                    </Col>
                    <Col sm={6} className="offset-sm-1">
                        <Fade bottom>
                            <ul className="list-mainmenu mb-0 display-2">
                                {this.props.data.tailored.map((item, index) => <li key={'fabric-' + index}><Link to={item.tailored_link === 'contact' ? '/contact' :'/solutions/' + this.props.match.params.page + item.tailored_link} className="custom-cursor">{item.tailored_name}</Link></li>)}
                            </ul>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(Fabrics);